import { Button, useToast, Flex, Icon, Heading, Spinner, Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { cashfree } from './util'
import { FaCreditCard, FaPaypal, FaStripe, FaRupeeSign, FaTimesCircle } from 'react-icons/fa';
const userCurrency = localStorage.getItem('userCurrency');

const baseUrl = process.env.REACT_APP_BASE_URL;


const PaymentPage = () => {
  let [draft, setDraft] = useState(null);
  let [sessionId, setSessionId] = useState("")
  const [loading, setLoading] = useState(true);
  let [cartProducts, setcartProducts] = useState([]);
  let [userID, setUserID] = useState("");
  let [totalPrice, setTotalPrice] = useState([]);
  let [totalPriceUSD, setTotalPriceUSD] = useState([]);
  let toast = useToast();
  let navigate = useNavigate();
  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const [phone, setphone] = useState(null);
  const [finalAmount, setFinalAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [cart, setCart] = useState([]);
  const orderId = localStorage.getItem('newOrderId');
  const [discountData, setDiscountData] = useState(null);


  let getorderdraft = async () => {
    try {
      let response = await axios.get(`https://api.digiblocks.tech/allOrders/${orderId}`);
      console.log(response.data);
      let totalPriceInUSD = response.data?.cartProducts?.reduce((total, product) => {
        return total + ((product.priceUSD - ((product.discount * product.priceUSD) / 100)) * product.quantity);
      }, 0);
      const response1 = await axios.get(
        "https://api.digiblocks.tech/api/discounts"
      );

      let x=0;
      if(response1.data[0].discount && response1.data[0].discount>0 && totalPriceInUSD>=response1.data[0].priceUSD) {
        console.log("OFFER APPLIED FOR USD ORDERS")
         x= (((totalPriceInUSD*Number(response1?.data[0] ? response1.data[0].discount: 0 ))/100))
         totalPriceInUSD-=x;
      }

      //adding shiiping charges for USD
      totalPriceInUSD += response.data?.shippingCharges;

console.log(totalPriceInUSD)
      setTotalPriceUSD(totalPriceInUSD);
      setDiscount(response.data?.discount);
      setDraft(response.data);
      setTotalPrice(response.data.finalAmount);
      setUserID(response.data.userID);
      setcartProducts(response.data?.cartProducts);
      setShippingFee(response.data?.shippingCharges);
      setCart(response.data?.cartProducts);
      setname(response.data?.customerInfo?.billing?.customerName);
      setemail(response.data?.customerInfo?.billing?.email);
      setphone(response.data?.customerInfo?.billing?.phone);
      setFinalAmount(response.data?.finalAmount)

      // You might want to return the data or do something else with it
      return response.data;
    } catch (error) {
      console.error("Error fetching order draft:", error);
      // Handle the error, throw it, or return an error response as needed
    }
  };

  const fetchDiscountData = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/api/discounts');
      setDiscountData(response.data[0]);
    } catch (error) {
      console.error('Error fetching discount data:', error);
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    fetchDiscountData();
    getorderdraft();
    getOrderStatus();
  }, []);


  const getOrderStatus = async () => {
    let cashfreeStatusID = localStorage.getItem('cashfreeStatus');
    try {
      let response = await axios.get(`https://api.digiblocks.tech/api/cashfree/order/${cashfreeStatusID}`);
      setSessionId(response.data.payment_session_id);
      return response.data.payment_session_id;
    } catch (error) {
      console.error('Error fetching order status:', error);
      setLoading(false);
    }
  };

  let paymentFunctionRazorpay = async () => {
    try {
      navigate("/razorpay")

    } catch (error) {
      console.error('Error in paymentFunctionRazorpay:', error);
    }
  };

  let paymentFunction = async () => {
    try {
      const response = await fetch('https://api.digiblocks.tech/pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({

          items: [{
            title: "Your Orders",
            _id: userID,
            priceUSD: Number((totalPriceUSD).toFixed(2).toString()),
            currency: 'USD',
            quantity: 1
          }]
        }),
        credentials: 'include',
      });

      if (response.ok) {
        const responseData = await response.json();
        window.open(responseData.paymentUrl, "_self");
      } else {
        console.error('Error initiating payment. Server returned:', response.status, response.statusText);
        toast({
          title: "Error",
          description: "Error initiating payment.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error initiating payment:', error.message);
      toast({
        title: "Error",
        description: "Error initiating payment.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  let paymentFunctionPaypal = async () => {
    try {
      paymentFunction();
    } catch (error) {
      console.error('Error in paymentFunctionPapal:', error);
    }

  };

  let paymentFunctionStripe = async () => {
    try {
      const stripe = await loadStripe(
        "pk_test_51P9067SIrbkdxJsZ3xnV0DVCXc4BlOGOMdYA4IagvhzulmEmreND07nvKbGadD4GXGKd6qeUMSwvGy4o7Bahl9Od00yIAMCpL3"
      );
      const body = { finalAmount }; // Corrected typo in "products"
      const headers = { "Content-Type": "application/json" }; // Corrected header name
      const response = await axios.post("https://api.digiblocks.tech/create-checkout-session", body, {
        headers: headers,
      });
      const session = response.data; // No need to parse JSON, axios does it automatically

      const result = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      // if any errors are encountered
      if (result.error) {
        // console.log(result.error);
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
    }
  };

  const paymentFunctionCashfree = async () => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      returnUrl: "${baseUrl}/cashfree-payment-successful",
    };

    if (sessionId) {
      cashfree.checkout(checkoutOptions).then((result) => {
        if (result.error) {
          console.log("User has closed the popup or there is some payment error, Check for Payment Status");
          console.log(result.error);
        }
        if (result.redirect) {
          console.log("Payment will be redirected");
        }
        if (result.paymentDetails) {
          console.log("Payment has been completed, Check for Payment Status");
          console.log(result.paymentDetails.paymentMessage);
        }
      });
    }
  };



  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      bgGradient="linear(to-t, #4FD1C5, #38B2AC)"
      p={4}
    >
      <Box
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="xl"
        textAlign="center"
        maxW="md"
      >
        {loading ? (
          <Spinner size="xl" color="teal.500" />
        ) : draft ? (
          <>
            <Heading fontSize="2xl" mb={6} color="black.500">
              Proceed To Pay
            </Heading>
            <Flex m="auto" justifyContent="space-around" mb={6}>
              {userCurrency === "INR" ? (
                <>
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    size="lg"
                    onClick={() => paymentFunctionRazorpay()}
                  >
                    <Icon as={FaRupeeSign} mr={2} /> RazorPay
                  </Button>

                  <Button
                    colorScheme="purple"
                    variant="solid"
                    size="lg"
                    display={"none"}
                    onClick={() => paymentFunctionCashfree()}
                  >
                    <Icon as={FaCreditCard} mr={2} /> CashFree
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    colorScheme="orange"
                    variant="solid"
                    size="lg"
                    onClick={() => paymentFunctionPaypal()}
                  >
                    <Icon as={FaPaypal} mr={2} /> PayPal
                  </Button>

                  <Button
                    colorScheme="purple"
                    variant="solid"
                    size="lg"
                    display={"none"}
                    onClick={() => paymentFunctionCashfree()}
                  >
                    <Icon as={FaCreditCard} mr={2} /> CashFree
                  </Button>

                  <Button
                    colorScheme="blue"
                    variant="solid"
                    display="none"
                    size="lg"
                    onClick={() => paymentFunctionStripe()}
                  >
                    <Icon as={FaStripe} mr={2} /> Stripe
                  </Button>
                </>
              )}
            </Flex>
            <Text color="gray.700" fontSize="md" mb={4}>
              Click on the above button to proceed with the payment securely.
            </Text>
          </>
        ) : (
          <Flex direction="column" align="center" justify="center">
            <Heading color="red.500" mb={4}>
              <Icon as={FaTimesCircle} mr={2} /> An Error Occurred
            </Heading>
            <Text color="gray.600" mb={6}>
              We're sorry, but there was an error fetching the order details.
              Please try again later.
            </Text>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => getorderdraft()}
            >
              Retry
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default PaymentPage;