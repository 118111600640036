import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Badge,
  Button,
  Stack,
  Input,
  InputGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  useToast,
  SimpleGrid,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign, FaPoundSign, FaEuroSign, FaYenSign } from 'react-icons/fa';
import { IoLogoUsd } from 'react-icons/io';
import { TbCurrencyDollarAustralian } from 'react-icons/tb';

const currencyIcons = {
  INR: <FaRupeeSign />,
  USD: <IoLogoUsd />,
  EUR: <FaEuroSign />,
  GBP: <FaPoundSign />,
  JPY: <FaYenSign />,
  CAD: <IoLogoUsd />,
  AUD: <TbCurrencyDollarAustralian />,
};

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("timeOfPayment");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [trackingDetails, setTrackingDetails] = useState({
    courier: "",
    trackingId: "",
    trackingUrl: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusOrderId, setStatusOrderId] = useState("");
  const [couriers, setCouriers] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const fetchOrders = async () => {
    try {
      const response = await axios.get("https://api.digiblocks.tech/allOrders");
      const paidOrders = response.data.filter(order => order.isPaid === true);
      setOrders(paidOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
      // Handle the error, e.g., show a toast message
    }
  };
  const fetchCouriers = async () => {
    try {
      const response = await axios.get(
        "https://api.digiblocks.tech/adminsetting/couriers"
      );
      setCouriers(response.data.map((courier) => courier.couriers));
    } catch (error) {
      console.error("Error fetching couriers:", error.message);
    }
  };
  useEffect(() => {
    fetchOrders();
    fetchCouriers();
  }, []);
  const searchFilter = (order) => {
    const { _id } = order;
    const { customerInfo } = order.customerInfo;

    return (
      _id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customerInfo.shipping.customerName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      customerInfo.shipping.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };
  const sortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? <ChevronUpIcon /> : <ChevronDownIcon />;
    }
    return null;
  };
  const generateTrackingUrl = (courier, trackingId) => {
    switch (courier.toLowerCase()) {
      case "dhl":
        return `https://www.dhl.com/in-en/home/tracking.html?tracking-id=${trackingId}&submit=1`;
      case "ups":
        return `https://www.ups.com/track?loc=en_US&tracknum=${trackingId}&requester=ST&fromrecent=1/trackdetails`;
      case "shiprocket":
        return `https://shiprocket.co/tracking/${trackingId}`;
      default:
        return "";
    }
  };
  const openTrackingModal = (order) => {
    setSelectedOrder(order);
    if (order.trackingId !== "") {
      setTrackingDetails({
        courier: order.courier,
        trackingId: order.trackingId,
        trackingUrl: generateTrackingUrl(
          order.courier,
          order.trackingId
        ),
      });
    } else {
      setTrackingDetails({
        courier: "",
        trackingId: "",
        trackingUrl: "",
      });
    }
    setIsModalOpen(true);
  };
  const closeTrackingModal = () => {
    setIsModalOpen(false);
    setTrackingDetails({
      courier: "",
      trackingId: "",
      trackingUrl: "",
    });
    setSelectedOrder(null);
  };
  const handleStatusFilterChange = (e) => {
    const selectedStatus = e.target.value;
    setSelectedStatusFilter(selectedStatus);
  };
  const saveTrackingDetails = async () => {
    try {
      if (!trackingDetails.courier || !trackingDetails.trackingId) {
        toast({
          title: "Error",
          description: "Courier and Tracking ID are mandatory.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      const trackingUrl = generateTrackingUrl(
        trackingDetails.courier,
        trackingDetails.trackingId
      );
      const response = await axios.put(
        `https://api.digiblocks.tech/updateTracking/${selectedOrder._id}`,
        { ...trackingDetails, trackingUrl }
      );
      closeTrackingModal();
      toast({
        title: "Success",
        description: "Tracking details saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving tracking details:", error.message);
      toast({
        title: "Error",
        description: "Failed to save tracking details.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const openStatusModal = (orderId) => {
    setStatusOrderId(orderId);
    setStatusModalOpen(true);
  };
  const closeStatusModal = () => {
    setStatusModalOpen(false);
    setSelectedStatus("");
    setStatusOrderId("");
  };
  const handleSaveStatus = async () => {
    try {
      if (!selectedStatus) {
        toast({
          title: "Error",
          description: "Status is mandatory.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      await axios.put(`https://api.digiblocks.tech/updateStatus/${statusOrderId}`, {
        status: selectedStatus,
      });
      // Update the local state with the updated order
      const updatedOrders = orders.map((order) =>
        order._id === statusOrderId
          ? { ...order, status: selectedStatus }
          : order
      );
      setOrders(updatedOrders);
      toast({
        title: "Success",
        description: `Status updated to ${selectedStatus}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      closeStatusModal();
    } catch (error) {
      console.error("Error updating status:", error.message);
      toast({
        title: "Error",
        description: "Failed to update status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  return (
    <Stack spacing={6} p={6}>
      <Heading>All Orders</Heading>
      <InputGroup mb={4}>
        <Input
          placeholder="Search by Order ID, Email, or Customer Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Select
          placeholder="Filter by Status"
          ml={2}
          value={selectedStatusFilter}
          onChange={handleStatusFilterChange}
        >
          <option value="">All</option>
          <option value="new">New</option>
          <option value="inprogress">InTransit(Shipped/InTransit)</option>
          <option value="completed">Completed(Delivered)</option>
        </Select>
      </InputGroup>
      <Table colorScheme="green" size="sm" display={["none", "none", "block"]}>
        <Thead>
          <Tr>
            <Th onClick={() => handleSort("orderID")}>
              Order ID {sortIcon("orderID")}
            </Th>
            <Th onClick={() => handleSort("customerName")}>
              Customer Name {sortIcon("customerName")}
            </Th>
            <Th onClick={() => handleSort("email")}>Email {sortIcon("email")}</Th>
            <Th onClick={() => handleSort("phone")}>Phone {sortIcon("phone")}</Th>
            <Th onClick={() => handleSort("finalAmount")}>
              Total Amount {sortIcon("finalAmount")}
            </Th>
            <Th onClick={() => handleSort("timeOfPayment")}>
              Time of Payment {sortIcon("timeOfPayment")}
            </Th>
            <Th onClick={() => handleSort("status")}>
              Status {sortIcon("status")}
            </Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders
            .filter(searchFilter)
            .filter((order) =>
              selectedStatusFilter
                ? order.status === selectedStatusFilter
                : true
            )
            .sort((a, b) => {
              const dateA = new Date(a.timeOfPayment || "");
              const dateB = new Date(b.timeOfPayment || "");

              if (sortOrder === "asc") {
                return dateA - dateB;
              } else {
                return dateB - dateA;
              }
            })
            .map((order) => (
              <Tr key={order._id}>
                <Td>{order._id}</Td>
                <Td>{order.customerInfo.shipping.customerName}</Td>
                <Td>{order.customerInfo.shipping.email}</Td>
                <Td>{order.customerInfo.shipping.phone}</Td>
                <Td>{currencyIcons[order?.userCurrency]}{order?.finalAmount}</Td>
                <Td>{order.timeOfPayment}</Td>
                <Td>
                  <Badge
                    colorScheme={
                      order.status === "new"
                        ? "orange"
                        : order.status === "inprogress"
                          ? "blue"
                          : "green"
                    }
                    variant="outline"
                  >
                    {order.status === "inprogress" ? "Shipped/InTransit" : order.status === "completed" ? "Delivered" : order.status}
                  </Badge>

                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    m="2"
                    fontSize={"sm"}
                    onClick={() => {
                      navigate(`/manager/orders/${order._id}`);
                    }}
                  >
                    View Details / Packing Slip
                  </Button>
                  {order.trackingId !== "" ? (
                    <Button
                      colorScheme="green"
                      fontSize={"sm"}
                      size="sm"
                      m="1"
                      onClick={() => window.location.href = order.trackingUrl}
                    >
                      Track Order
                    </Button>
                  ) : (
                    <Button
                      onClick={() => openTrackingModal(order)}
                      fontSize={"sm"}
                      m="1"
                      size="sm"
                    >
                      Add Tracking Details
                    </Button>
                  )}
                  <Button
                    colorScheme="purple"
                    size="sm"
                    m="1"
                    onClick={() => openStatusModal(order._id)}
                  >
                    Update Status
                  </Button>
                </Td>

              </Tr>
            ))}
        </Tbody>
      </Table>
      {/* Tracking Details Modal */}
      <Modal isOpen={isModalOpen} onClose={closeTrackingModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tracking Details</ModalHeader>
          <ModalBody>
            <Select
              placeholder="Select Courier"
              mb={3}
              value={trackingDetails.courier}
              onChange={(e) => {
                const selectedCourier = e.target.value;
                setTrackingDetails({
                  ...trackingDetails,
                  courier: selectedCourier,
                  trackingUrl: generateTrackingUrl(
                    selectedCourier,
                    trackingDetails.trackingId
                  ),
                });
              }}
            >
              {couriers.map((courier, index) => (
                <option key={index} value={courier}>
                  {courier}
                </option>
              ))}
            </Select>
            <Input
              placeholder="Tracking ID / AWB Code"
              mb={3}
              value={trackingDetails.trackingId}
              onChange={(e) =>
                setTrackingDetails({
                  ...trackingDetails,
                  trackingId: e.target.value,
                })
              }
            />
            <Input
              placeholder="Tracking URL"
              value={generateTrackingUrl(
                trackingDetails.courier,
                trackingDetails.trackingId
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={saveTrackingDetails}>
              Save
            </Button>
            <Button onClick={closeTrackingModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Status Update Modal */}
      <Modal isOpen={statusModalOpen} onClose={closeStatusModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Status</ModalHeader>
          <ModalBody>
            <Select
              placeholder="Select Status"
              mb={3}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="new">New</option>
              <option value="inprogress">In Progress(Shipped/InTransit)</option>
              <option value="completed">Completed(Delivered)</option>
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveStatus}>
              Save
            </Button>
            <Button onClick={closeStatusModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <SimpleGrid display={["grid", "", "none"]} columns={[1, 1, 0]} gap={6}>
        {orders
          .filter(searchFilter)
          .filter((order) =>
            selectedStatusFilter
              ? order.status === selectedStatusFilter
              : true
          )
          .sort((a, b) => {
            const dateA = new Date(a.timeOfPayment || "");
            const dateB = new Date(b.timeOfPayment || "");

            if (sortOrder === "asc") {
              return dateA - dateB;
            } else {
              return dateB - dateA;
            }
          })
          .map((order) => (
            <Flex key={order._id} w={"95%"} p={"2%"} m={"auto"} direction={"column"} gap={3} border={"1px solid teal"} borderRadius={"15px"}>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Order ID :</Text>
                <Text>{order._id}</Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Customer Name :</Text>
                <Text>{order.customerInfo.shipping.customerName}</Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Email :</Text>
                <Text>{order.customerInfo.shipping.email}</Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Phone :</Text>
                <Text>{order.customerInfo.shipping.phone}</Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Total Amount :</Text>
                <Flex alignItems={"center"}>
                  <Text>{currencyIcons[order?.userCurrency]}</Text>
                  <Text>{order?.finalAmount}</Text>
                </Flex>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Time of Payment :</Text>
                <Text>{order.timeOfPayment ? new Date(order.timeOfPayment).toLocaleString() : ''}</Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Status :</Text>
                <Text>     <Badge
                  colorScheme={
                    order.status === "new"
                      ? "orange"
                      : order.status === "inprogress"
                        ? "blue"
                        : "green"
                  }
                  variant="outline"
                >
                  {order.status === "inprogress" ? "Shipped/InTransit" : order.status === "completed" ? "Delivered" : order.status}
                </Badge></Text>
              </Box>
              <Box gap={2} alignItems={"center"}>
                <Text m={0} fontWeight={"bold"}>Action :</Text>
                <Box>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    m="2"
                    fontSize={"sm"}
                    onClick={() => {
                      navigate(`/manager/orders/${order._id}`);
                    }}
                  >
                    View Details / Packing Slip
                  </Button>
                  {order.trackingId !== "" ? (
                    <Button
                      colorScheme="green"
                      fontSize={"sm"}
                      size="sm"
                      m="1"
                      onClick={() => window.location.href = order.trackingUrl}
                    >
                      Track Order
                    </Button>
                  ) : (
                    <Button
                      onClick={() => openTrackingModal(order)}
                      fontSize={"sm"}
                      m="1"
                      size="sm"
                    >
                      Add Tracking Details
                    </Button>
                  )}
                  <Button
                    colorScheme="purple"
                    size="sm"
                    m="1"
                    onClick={() => openStatusModal(order._id)}
                  >
                    Update Status
                  </Button>
                </Box>
              </Box>

            </Flex>
          ))}
      </SimpleGrid>
    </Stack>
  );
};

export default OrderManagement;
