import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../Context/AppContext';
import Swal from 'sweetalert2';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { adminAuth } = useContext(AppContext);
  let userRole = sessionStorage.getItem("admintype") || "";

  if (userRole && adminAuth && allowedRoles.includes(userRole)) {
    return <>{children}</>;
  } else {
    userRole === "admin" ? <></> : Swal.fire({
      icon: 'error',
      title: 'Access Denied',
      html: 'You are not authorized to access this page. <br/> Ask your Manager to get access !!!',
    });
    return <Navigate to="/manager" />;    
  }
}

export default PrivateRoute;
