import React, { useContext, useState, useEffect } from 'react';
import {
  Box,
  Center,
  Text,
  SimpleGrid,
  Image,
  Button,
  Heading,
  Stack,
  useToast,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { FaHeart, FaShoppingCart } from 'react-icons/fa';
import axios from 'axios';
import { AppContext } from '../../Context/AppContext';
import ShareButton from '../ProductsPageComponents/ShareButton';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useQuery } from 'react-query';
import Tooltip from '../ProductsPageComponents/ToolTip';

const baseUrl = process.env.REACT_APP_BASE_URL;



const userCurrency = localStorage.getItem('userCurrency');
const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const MostViewProducts = () => {
  const toast = useToast();
  const { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);
  const [showSet1, setShowSet1] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: productsSet1, isLoading: loadingSet1 } = useQuery('mostViewedSet1', () => getdata(0, 4));
  const { data: productsSet2, isLoading: loadingSet2 } = useQuery('mostViewedSet2', () => getdata(-4));

  const showSet = (set) => {
    setShowSet1(set);
  };

  const getdata = async (start, end) => {
    try {
      const data = await axios.get('https://api.digiblocks.tech/products/mostviewed');
      const slicedData = data.data;
      return start === 0 ? slicedData.slice(start, end) : slicedData.slice(start);
    } catch (error) {
      console.error('Error fetching most viewed products:', error);
    }
  };

  const getcartLength = async () => {
    const userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        const response = await axios.get('https://api.digiblocks.tech/getcartitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setCartLength(response.data.data.length);
      } catch (error) {
        console.error('Error getting cart length:', error);
      }
    }
  };

  const addtocartfunctionGrid = async (id, e) => {
    setRefresh(!refresh);
    const userToken = localStorage.getItem('userToken');
    e.stopPropagation();
    if (userToken) {
        try {
            const data = await axios.post(
                'https://api.digiblocks.tech/addtocart',
                { productId: id },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (data.data.message === 'Item added to cart successfully.') {
                setRefresh(!refresh);
                toast({
                    title: 'Added to Cart.',
                    description: 'Item added to cart successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else if (data.data.msg === 'Product already in cart') {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in cart.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
            getcartLength();
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    } else {
        // Get current cart from cookies
        const cart = JSON.parse(Cookies.get('guestCart') || '[]');

        // Add product to cart
        if (!cart.includes(id)) {
            cart.push(id);
            Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
            toast({
                title: "Product added to the cart.",
                description: 'Please login to proceed to checkout.',
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Already in Cart.',
                description: 'Product already in the cart.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        }
    }
};

  const addToWishlistFunction = async (id) => {
    let userToken = localStorage.getItem('userToken');
    if (!userToken) {
      toast({
        title: 'Please login first to add this item to your wishlist.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      let wishlistdata = await axios.post(
        'https://api.digiblocks.tech/addtowishlist',
        {
          productId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      toast({
        title: 'Item added to wishlist successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      getwishlistlength();
    } catch (error) {
      console.error('Error adding to wishlist:', error);

      toast({
        title: 'Failed to add item to wishlist. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const getwishlistlength = async () => {
    const userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        const response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setWishlistLength(response.data.data.length);
      } catch (error) {
        console.error('Error getting wishlist length:', error);
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries('mostViewedSet1');
    queryClient.invalidateQueries('mostViewedSet2');
  }, [refresh]);

  const products = showSet1 ? productsSet1 : productsSet2;

  const headingStyle = {
    color: '#6FA82F',
    fontFamily: 'DM Serif Display',
    fontSize: { base: '24px', md: '46px' },
    lineHeight: { base: '32px', md: '58px' },
    fontWeight: 400,
  };

  const productTitleStyle = {
    fontSize: { base: '18px', md: '22px' },
    lineHeight: { base: '28px', md: '34px' },
    fontWeight: 400,
    fontFamily: 'DM Serif Display',
  };

  const textStyle = {
    fontSize: { base: '14px', md: '18px' },
    lineHeight: { base: '24px', md: '28px' },
    textAlign: 'center',
    display: { base: 'block', md: 'inline-block' },
  };

  return (
    <Box mt={8} p={4} bg="white" maxW="1200px" mx="auto">
      <Center>
        <Heading as={"h1"} cursor={'pointer'} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} style={headingStyle}>
          {isHovered ? 'Mostly Viewed' : 'Seen By Many'}
        </Heading>
      </Center>
      <Center>
        <Text mt={4} style={textStyle}>
          Knitsilk's products are crafted with care and dedication.
        </Text>
      </Center>
      <Center>
        <Text mt={-3} style={textStyle}>
          We prioritize quality materials and craftsmanship to bring you the finest products.
        </Text>
      </Center>

      {loadingSet1 || loadingSet2 ? (
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} spacing={8} mt={8} p={4}>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} height="320px" />
          ))}
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 4 }} spacing={8} mt={8} p={4}>
          {products.map((product) => (
            <Box key={product._id} bg="#f9f9f9" shadow="md" p={4} position="relative">
              <Flex p={2} direction={'column'} alignItems={'center'} gap={5} position={'absolute'} top={0} right={0}>
                <Button
                  zIndex={'999'}
                  _hover={{ transform: 'scale(1.3)', transition: 'transform 0.3s ease-in-out' }}
                  size={'md'}
                  borderRadius={'50%'}
                  onClick={(e) => {
                    e.stopPropagation();
                    addToWishlistFunction(product._id);
                  }}
                >
                  <FaHeart color="rgb(111,168,47)" />
                </Button>
                <ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} />
              </Flex>
              <Center>
                <Image
                  src={product.photos[0]}
                  alt={product.title}
                  _hover={{ transform: 'scale(1.1)', cursor: "pointer" }}
                  transition="transform 0.2s"
                  onClick={() => {
                    // Get the existing recently viewed products from localStorage
                    const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

                    // Add the current product ID to the recently viewed products array
                    const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];

                    // Ensure that only unique product IDs are stored
                    const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];

                    // Store the updated recently viewed products array back into localStorage
                    localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));

                    // Redirect to the single product page
                    navigate(`/products/${product._id}`);
                  }}
                  height="200px"
                />
              </Center>
              <Tooltip content={product.title}>
              <Text mt={4} fontFamily="DM Serif Display" fontSize="lg" textAlign="center" style={productTitleStyle}>
                {product.title.split(' ').slice(0, 8).join(' ')}...
              </Text>
              </Tooltip>
              <Center>
                <Text display={'flex'} gap={"5px"} alignItems={'center'} fontSize={'18px'} fontWeight={500}>
                  {currencyIcons[userCurrency]} {product[`price${userCurrency}`]}
                </Text>
              </Center>
              <Text m={0} textAlign={"center"} fontSize={'16px'}>
                {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
              </Text>
              <Center>

            <Stack spacing={4} mt={2} mb={2 }>
            <Button
                colorScheme="green"
                bg="#e8eee4"
                color="#6FA82F"
                borderRadius={0}
                _hover={{ bg: '#6FA82F', color: 'white' }}
                rightIcon={<FaShoppingCart />}
                onClick={(e) => {
                  addtocartfunctionGrid(product._id, e);
                }}
            >
                Add to Cart
            </Button>


            <Button
              colorScheme="green"
              bg="#6FA82F"
              color="white"
              borderRadius={0}
              onClick={(e) => {
                  addtocartfunctionGrid(product._id,e);
                    navigate('/checkout');
              }}
                _hover={{ bg: "black", color: "white" }}
                rightIcon={<FaShoppingCart />}
            >
                Buy Now
            </Button>

            </Stack>


                {/* <Button
                  colorScheme="green"
                  bg="#e8eee4"
                  color="#6FA82F"
                  borderRadius={0}
                  w="70%"
                  mt={4}
                  mb={4}
                  _hover={{ bg: '#6FA82F', color: 'white' }}
                  rightIcon={<FaShoppingCart />}
                  isFullWidth
                  p={'0 70px'}
                  onClick={(e) => {
                    addtocartfunctionGrid(product._id, e);
                  }}
                >
                  Add to Cart
                </Button> */}
              </Center>
            </Box>
          ))}
        </SimpleGrid>
      )}
      <Center width={'100%'} mt={12}>
        <Button
          colorScheme="green"
          size="sm"
          borderRadius={'50%'}
          bg={showSet1 ? '#6FA82F' : '#e8eee4'}
          color={showSet1 ? 'white' : '#155b4e'}
          onClick={() => showSet(true)}
          mr={2}
          _hover={{ bg: showSet1 ? '#6FA82F' : '#232424', color: 'white' }}
        ></Button>
        <Button
          colorScheme="green"
          size="sm"
          borderRadius={'50%'}
          bg={showSet1 ? '#e8eee4' : '#6FA82F'}
          color={showSet1 ? '#155b4e' : 'white'}
          onClick={() => showSet(false)}
          _hover={{ bg: showSet1 ? '#232424' : '#6FA82F', color: 'white' }}
        ></Button>
      </Center>
    </Box>
  );
};

export default MostViewProducts;
