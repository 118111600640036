import {
  Box,
  Center,
  Heading,
  SimpleGrid,
  Text,
  Image,
  Button,
  Flex,
  useToast,
  Stack,
  Skeleton,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useContext, useState, useEffect } from 'react';
import { FaHeart, FaShoppingCart } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import axios from 'axios';
import { AppContext } from '../../Context/AppContext';
import ShareButton from '../ProductsPageComponents/ShareButton';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Tooltip from '../ProductsPageComponents/ToolTip';

const userCurrency = localStorage.getItem('userCurrency');
const currencyIcons = {
  INR: '₹',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CAD: '$',
  AUD: '$',
};

const baseUrl = process.env.REACT_APP_BASE_URL;


const Bestseller = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const toast = useToast();
  const { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);
  const getdata = async () => {
    try {
      let response = await axios.get("https://api.digiblocks.tech/products/bestseller");
      let limitedProducts = response.data.slice(0, 4);
      return limitedProducts;
    } catch (error) {
      console.error('Error fetching bestseller products:', error);
      throw new Error('Failed to fetch bestseller products');
    }
  };

  const { data: products, isLoading, isError } = useQuery('bestsellers', getdata);

  useEffect(() => {
    getcartLength();
  }, [products]);

  const headingStyle = {
    color: '#6FA82F',
    fontFamily: 'DM Serif Display',
    fontSize: { base: '24px', md: '46px' },
    lineHeight: { base: '32px', md: '58px' },
    fontWeight: 400,
  };

  const textStyle = {
    fontSize: { base: '14px', md: '18px' },
    lineHeight: { base: '24px', md: '28px' },
    textAlign: 'center',
    display: { base: 'block', md: 'inline-block' },
  };


  const getcartLength = async () => {
    let userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        let response = await axios.get('https://api.digiblocks.tech/getcartitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setCartLength(response?.data?.data?.length);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    }
  };

  const addToWishlistFunction = async (id) => {
    let userToken = localStorage.getItem('userToken');
    if (!userToken) {
      toast({
        title: 'Please login first to add this item to your wishlist.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      let wishlistdata = await axios.post(
        'https://api.digiblocks.tech/addtowishlist',
        {
          productId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      toast({
        title: 'Item added to wishlist successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      getwishlistlength();
    } catch (error) {
      console.error('Error adding to wishlist:', error);

      toast({
        title: 'Failed to add item to wishlist. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const getwishlistlength = async () => {
    let userToken = localStorage.getItem('userToken') || '';
    if (userToken) {
      try {
        let response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setWishlistLength(response?.data?.data?.length);
      } catch (error) {
        console.error('Error fetching wishlist items:', error);
      }
    }
  };

  const addtocartfunctionGrid = async (id, e) => {
    setRefresh(!refresh);
    const userToken = localStorage.getItem('userToken');
    e.stopPropagation();
    if (userToken) {
        try {
            const data = await axios.post(
                'https://api.digiblocks.tech/addtocart',
                { productId: id },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (data.data.message === 'Item added to cart successfully.') {
                setRefresh(!refresh);
                toast({
                    title: 'Added to Cart.',
                    description: 'Item added to cart successfully.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            } else if (data.data.msg === 'Product already in cart') {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in cart.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            }
            getcartLength();
        } catch (error) {
            console.error('Error adding to cart:', error);
        }
    } else {
        // Get current cart from cookies
        const cart = JSON.parse(Cookies.get('guestCart') || '[]');

        // Add product to cart
        if (!cart.includes(id)) {
            cart.push(id);
            Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
            toast({
                title: "Product added to the cart.",
                description: 'Please login to proceed to checkout.',
                status: "info",
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: 'Already in Cart.',
                description: 'Product already in the cart.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            });
        }
    }
};

  if (isLoading) {
    return (
      <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 4 }} spacing={8} mt={8}>
        {[...Array(4)].map((_, index) => (
          <Box key={index} maxWidth={['80%', '90%', '1200px']} m="auto" bg="#f9f9f9" shadow="md" p={4} position="relative">
            <Skeleton height="200px" />
            <Skeleton height="20px" mt="4" />
            <Skeleton height="20px" mt="2" />
            <Skeleton height="20px" mt="2" />
            <Skeleton height="20px" mt="2" />
            <Skeleton height="20px" mt="2" />
            <Skeleton height="40px" mt="4" />
          </Box>
        ))}
      </SimpleGrid>
    );

  }
  return (
    <Box mt={'8%'} p={4} bg="white" maxW="1200px" mx="auto">
      <Center>
        <Heading
          as={"h1"}
          cursor={'pointer'}
          style={headingStyle}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          {isHovered ? 'Loved By All' : 'Best Seller'}
        </Heading>
      </Center>
      <Center>
        <Text mt={4} style={textStyle} fontSize={'16px'}>
          Shop the Top Picks: Uncover the Most Popular Products in Our Collection.
        </Text>
      </Center>

      <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 4 }} spacing={8} mt={8}>
        {products && products.map((product) => (
          <Box key={product._id} w={['80%', '90%', '100%']} m="auto" bg="#f9f9f9" shadow="md" p={4} position="relative">
            <Flex p={2} direction={'column'} alignItems={'center'} gap={5} position={'absolute'} top={0} right={0}>
              <Button
                zIndex={'999'}
                _hover={{ transform: 'scale(1.3)', transition: 'transform 0.3s ease-in-out' }}
                size={'md'}
                borderRadius={'50%'}
                onClick={(e) => {
                  e.stopPropagation();
                  addToWishlistFunction(product._id);
                }}
              >
                <FaHeart color="rgb(111,168,47)" />
              </Button>
              <ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} />
            </Flex>
            <Center>
              <Image src={product.photos[0]} alt={product.title} transition="transform 0.2s" _hover={{ transform: 'scale(1.1)', cursor: "pointer" }} height="200px" onClick={() => {
                // Get the existing recently viewed products from localStorage
                const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];

                // Add the current product ID to the recently viewed products array
                const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];

                // Ensure that only unique product IDs are stored
                const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];

                // Store the updated recently viewed products array back into localStorage
                localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));

                // Redirect to the single product page
                navigate(`/products/${product._id}`);
              }} />
            </Center>
            <Tooltip content={product.title}>
              <Text mt={4} fontFamily='DM Serif Display' fontSize="lg" textAlign="center">
                {product.title.split(' ').slice(0, 8).join(' ')}...
              </Text>
            </Tooltip>
            <Center>
              <Text display={'flex'} gap={"5px"} alignItems={'center'} fontSize={'18px'} fontWeight={500}>
                {currencyIcons[userCurrency]} {product[`price${userCurrency}`]}
              </Text>
            </Center>
            <Text m={0} textAlign={"center"} fontSize={'16px'}>
              {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
            </Text>
            <Center>

            <Stack spacing={4} mt={4} mb={2 }>
            <Button
                colorScheme="green"
                bg="#e8eee4"
                color="#6FA82F"
                borderRadius={0}
                _hover={{ bg: '#6FA82F', color: 'white' }}
                rightIcon={<FaShoppingCart />}
                onClick={(e) => {
                  addtocartfunctionGrid(product._id, e);
                }}
            >
                Add to Cart
            </Button>


            <Button
              colorScheme="green"
              bg="#6FA82F"
              color="white"
              borderRadius={0}
              onClick={(e) => {
                  addtocartfunctionGrid(product._id,e);
                    navigate('/checkout');
              }}
                _hover={{ bg: "black", color: "white" }}
                rightIcon={<FaShoppingCart />}
            >
                Buy Now
            </Button>

            </Stack>





              {/* <Button
                colorScheme="green"
                bg="#e8eee4"
                color="#6FA82F"
                w="70%"
                mt={4}
                borderRadius={0}
                mb={4}
                _hover={{ bg: '#6FA82F', color: 'white' }}
                rightIcon={<FaShoppingCart />}
                isFullWidth
                p={'0 70px'}
                onClick={(e) => {
                  addtocartfunctionGrid(product._id, e);
                }}
              >
                Add to Cart
              </Button> */}

            </Center>
          </Box>
        ))}
      </SimpleGrid>
      <Center mt={12}>
        <Button
          _hover={{ bg: '#232424' }}
          bg={'#6FA82F'}
          fontSize={['14px', '14px', '18px']}
          p={['25px', '30px']}
          color={'white'}
          borderRadius={'0'}
          m="auto"
          mt={'30px'}
        >
          <Flex onClick={() => navigate('/products')} gap={'10px'} alignItems={'center'}>
            <Text mt={3}>Find More</Text>
            <BsArrowRight />
          </Flex>
        </Button>
      </Center>
    </Box>
  );
};

export default Bestseller;
