import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Flex,
    SimpleGrid,
    useToast,
    Text,
    Input,
    Button,
    Card,
    CardBody,
    Image,
    Stack,
    Divider,
    CardFooter,
    IconButton,
    Heading,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import Tooltip from './ToolTip';
import { FaTimes, FaSearch } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductsSidebar from './ProductsSidebar';
import ProductsSidebarPhone from './ProductsSidebarPhone';
import ShareButton from '../ProductsPageComponents/ShareButton';
import axios from 'axios';
import { AppContext } from '../../Context/AppContext';
import { useContext } from 'react';
import { FaHeart, FaShoppingCart } from 'react-icons/fa';
import DogsComponent from '../DogsComponent';
import SaleBanner from './SaleBanner';
import anim from '../../assets/LOADING.mp4';
import BigOrderUi from '../Checkout.jsx/BigOrderUi';

const baseUrl = process.env.REACT_APP_BASE_URL;


const currencyIcons = {
    INR: '₹',
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    CAD: '$',
    AUD: '$',
};
const userCurrency = localStorage.getItem('userCurrency');
const ITEMS_PER_PAGE = 32;
const AllProducts = () => {
    const [showStyle, setshowStyle] = useState('grid');
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    let toast = useToast();
    let { setCartLength, setWishlistLength, setRefresh, refresh } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [suggestions, setSuggestions] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryFilter = queryParams.get('category');
    const subcategoryfilter = queryParams.get('subcategories');

    let getdatabycategory = async () => {
        setLoading(true)
        let response = await axios.get(`https://api.digiblocks.tech/products?category=${categoryFilter || ''}`);
        setProducts(response.data)
        setLoading(false)
    }
    let getdatabysubcategory = async () => {
        if (subcategoryfilter !== "All") {
            setLoading(true)
            let response = await axios.post("https://api.digiblocks.tech/products/filterBySubcategories", {
                category: categoryFilter,
                subcategories: subcategoryfilter
            });
            setLoading(false)
            setProducts(response.data)
        }
        else {
            setLoading(true)
            const response = await axios.get(`https://api.digiblocks.tech/products?category=${categoryFilter || ''}`);
            setLoading(false)
            setProducts(response.data)
        }
    }
    let getallproducts = async () => {
        setLoading(true);
        let response = await axios.get("https://api.digiblocks.tech/products");
        let allProducts = response.data;

        const searchParams = new URLSearchParams(location.search);
        const searchText = searchParams.get("search");

        if (searchText) {
            const searchWords = searchText.toLowerCase().split(" ");
            allProducts = allProducts.filter(product =>
                searchWords.some(word =>
                    product.title.toLowerCase().includes(word) ||
                    product.tags.some(tag =>
                        tag.toLowerCase().includes(word)
                    )
                )
            );
        }


        setProducts(allProducts);
        setLoading(false);
    };

    useEffect(() => {
        if (categoryFilter && !subcategoryfilter) {
            getdatabycategory();
        } else if (categoryFilter && subcategoryfilter) {
            getdatabysubcategory();
        } else {
            getallproducts();
        }
    }, [refresh, location.search]);


    const filteredProducts = Array.isArray(products) ? products.filter((product) => {
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    }
    ) : [];
    const indexOfLastProduct = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstProduct = indexOfLastProduct - ITEMS_PER_PAGE;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500); // 1000 milliseconds = 1 second
    };


    let getcartLength = async () => {
        let userToken = localStorage.getItem('userToken') || '';
        if (userToken) {
            let response = await axios.get('https://api.digiblocks.tech/getcartitems', {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setCartLength(response.data.data.length);
        }
    };
    const addtocartfunctionGrid = async (id, e) => {
        setRefresh(!refresh);
        const userToken = localStorage.getItem('userToken');
        e.stopPropagation();
        if (userToken) {
            try {
                const data = await axios.post(
                    'https://api.digiblocks.tech/addtocart',
                    { productId: id },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (data.data.message === 'Item added to cart successfully.') {
                    setRefresh(!refresh);
                    toast({
                        title: 'Added to Cart.',
                        description: 'Item added to cart successfully.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                } else if (data.data.msg === 'Product already in cart') {
                    toast({
                        title: 'Already in Cart.',
                        description: 'Product already in cart.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                getcartLength();
            } catch (error) {
                console.error('Error adding to cart:', error);
            }
        } else {
            // Get current cart from cookies
            const cart = JSON.parse(Cookies.get('guestCart') || '[]');

            // Add product to cart
            if (!cart.includes(id)) {
                cart.push(id);
                Cookies.set('guestCart', JSON.stringify(cart), { expires: 7 });
                toast({
                    title: "Product added to the cart.",
                    description: 'Please login to proceed to checkout.',
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: 'Already in Cart.',
                    description: 'Product already in the cart.',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };
    let getwishlistlength = async () => {
        let userToken = localStorage.getItem('userToken') || '';
        if (userToken) {
            let response = await axios.get('https://api.digiblocks.tech/getwishlistitems', {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setWishlistLength(response.data.data.length);
        }
    };
    const addToWishlistFunction = async (id) => {
        let userToken = localStorage.getItem('userToken');
        if (!userToken) {
            toast({
                title: 'Please login first to add this item to your wishlist.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        try {
            let wishlistdata = await axios.post(
                'https://api.digiblocks.tech/addtowishlist',
                {
                    productId: id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            toast({
                title: 'Item added to wishlist successfully!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            getwishlistlength();
        } catch (error) {
            console.error('Error adding to wishlist:', error);
            toast({
                title: 'Failed to add item to wishlist. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionsRef = useRef(null);

    const handleInputChange = (e) => {
        const input = e.target.value;
        setSearchTerm(input);
        const filteredSuggestions = products
            .filter((product) => product.title.toLowerCase().includes(input.toLowerCase()))
            .slice(0, 5);
        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);
    };
    const handleCardClick = (productId) => {
        navigate(`/products/${productId}`);
        setShowSuggestions(false);
    };
    const handleOutsideClick = (e) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(e.target)) {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const handleSearch = () => {
        if (searchTerm !== "") {
            const filteredProducts = products.filter(product => {
                const titleMatch = product.title.toLowerCase().includes(searchTerm.toLowerCase());
                const tagMatch = product.tags.some(tag =>
                    tag.toLowerCase().includes(searchTerm.toLowerCase())
                );
                return titleMatch || tagMatch;
            });
            setProducts(filteredProducts);
        } else if (categoryFilter && !subcategoryfilter) {
            getdatabycategory();

        } else if (categoryFilter && subcategoryfilter) {
            getdatabysubcategory();
        }
    };
    useEffect(() => {
        setCurrentPage(1);
    }, [refresh])
    return (
        <>

            <Flex
                minH={"120vh"}
                direction={['column', 'column', 'row']} mb={'2%'}>
                <ProductsSidebarPhone setCurrentPage={setCurrentPage} products={products} setProducts={setProducts} setshowStyle={setshowStyle} />
                <Flex mt={["", "", '2%']} ml={['0px', '0px', '5%']} mr={['0px', '0px', '5%']} w={'100%'} flex={'6'}>
                    <Box height={["70px", "70px", ""]} flex={['', '', '1']} p={3}  >
                        <ProductsSidebar setCurrentPage={setCurrentPage} products={products} setProducts={setProducts} setshowStyle={setshowStyle} />
                    </Box>
                    <Box flex={['6', '6', '5']} p={3}>
                        <Flex direction={['column', 'column', 'row']} alignItems={'center'} justifyContent={'space-between'} p={2}>
                            <Flex>
                                <Heading size="md">
                                    All Products
                                </Heading>
                            </Flex>
                            <Flex>
                                <Text fontSize={'18px'} fontWeight={''}>{products.length} {"   "}{"   "}{"   "}Products</Text>
                            </Flex>
                            <Flex w={["90%", "", "40%"]}>
                                <Input
                                    border="1px solid gray"
                                    borderRadius={'0px'}
                                    placeholder="Search Products..."
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                />&nbsp;
                                <IconButton
                                    bg={"rgb(111,168,47)"}
                                    color={"white"}
                                    size="md"
                                    onClick={handleSearch}
                                >
                                    <FaSearch />
                                </IconButton>
                            </Flex>
                        </Flex>
                        {searchTerm && showSuggestions && (
                            <Box position="absolute" right={["", "10", "16"]} zIndex="997" w={["70%", "70%", "25%"]} ref={suggestionsRef}>
                                <Card border="1px solid lightgray" borderRadius="5px">
                                    <CardBody>
                                        <Stack spacing={2}>
                                            {suggestions.map((suggestion) => (
                                                <Flex key={suggestion._id} justifyContent="space-between">
                                                    <Text
                                                        cursor="pointer"
                                                        onClick={() => handleCardClick(suggestion._id)}
                                                        _hover={{ textDecoration: 'underline' }}
                                                    >
                                                        {suggestion.title.slice(0, 70)} {suggestion.title.length > 70 && '...'}
                                                    </Text>
                                                    <Image _hover={{ cursor: "pointer" }} height="70px" src={suggestion.photos[0]} ml={4} />
                                                </Flex>
                                            ))}
                                        </Stack>
                                    </CardBody>
                                    <Box
                                        textAlign="right"
                                        p={2}
                                        bg={"none"}
                                        cursor="pointer"
                                        onClick={() => setShowSuggestions(false)}
                                        position="absolute"
                                        top={1}
                                        right={1}
                                        color={"red"}
                                    >
                                        <FaTimes />
                                    </Box>
                                </Card>
                            </Box>
                        )}
                        <Divider></Divider>
                        <BigOrderUi/>
                        {
                            loading ? <>
                                <Flex width="100%" direction="column" alignItems="center" justifyContent="center" minHeight="100vh">
                                    <video autoPlay loop muted width="700">
                                        <source src={anim} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </Flex>

                            </> : <>
                                {/* Grid view */}
                                <SimpleGrid p={4} display={showStyle === 'grid' ? 'grid' : ['grid', 'grid', 'none']} columns={[1, 1, 4]} spacing="30px">
                                    {products.length > 0 && currentProducts.length > 0 ? currentProducts.map((product) => (
                                        <Box key={product._id} pr={[5, , 0]}>
                                            <Card maxW="sm" border={'1px solid lightgray'} borderRadius={'5px'} h={"500px"} position={"relative"}>
                                                {product.discount > 0 && <SaleBanner percent={product.discount > 0 ? product.discount : 0} />}
                                                <CardBody onClick={() => handleCardClick(product._id)}>
                                                    <Image w={'100%'} h={"150px"} src={product?.photos?.[0] || ''} alt={product.title} _hover={{ cursor: "pointer", transform: "scale(1.1)", transition: "transform 0.5s ease;" }} borderRadius="lg"
                                                        onClick={() => {
                                                            const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
                                                            const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];
                                                            const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];
                                                            localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));
                                                            navigate(`/products/${product._id}`);
                                                            setRefresh(!refresh)
                                                        }}
                                                    />
                                                    <Stack mt="6">
                                                        <Tooltip content={product.title}>
                                                            <Text m={0} fontSize={['18px', '18px']} fontWeight={'600'}>
                                                                {product.title.split(' ').slice(0, 6).join(' ')}...
                                                            </Text>
                                                        </Tooltip>
                                                        <Text m={0} fontSize={'16px'}>
                                                            {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
                                                        </Text>
                                                        <Flex align="center" fontWeight={"semibold"}>
                                                            {currencyIcons[userCurrency]}
                                                            <Text fontSize={'16px'} m={0} ml={1}>
                                                                {product[`price${userCurrency}`]}
                                                            </Text>
                                                        </Flex>
                                                    </Stack>
                                                </CardBody>
                                                <CardFooter>

                                                    {/* xxx */}
                                                    <Flex w={'100%'} justifyContent={'center'} >
                                                        <Flex zIndex={"10"} p={2} direction={"column"} alignItems={"center"} gap={5} position={"absolute"} top={0} right={0}>
                                                            <Button
                                                                zIndex={"10"}
                                                                _hover={{ transform: "scale(1.3)", transition: "transform 0.3s ease-in-out" }}
                                                                size={'md'}
                                                                borderRadius={'50%'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    addToWishlistFunction(product._id);
                                                                }}
                                                            >
                                                                <FaHeart color="rgb(111,168,47)" />
                                                            </Button>
                                                            <ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} />

                                                    </Flex>

                                                    {/* Add to Cart and Buy Now Button */}

                                                    <Stack spacing={4} mt={2} mb={2 }>
                                                        <Button
                                                            colorScheme="green"
                                                            bg="#e8eee4"
                                                            color="#6FA82F"
                                                            borderRadius={0}
                                                            _hover={{ bg: '#6FA82F', color: 'white' }}
                                                            rightIcon={<FaShoppingCart />}
                                                            onClick={(e) => {
                                                                addtocartfunctionGrid(product._id, e);
                                                            }}
                                                        >
                                                            Add to Cart
                                                        </Button>

                                                        <Button
                                                            colorScheme="green"
                                                            bg="#6FA82F"
                                                            color="white"
                                                            borderRadius={0}
                                                            onClick={(e) => {
                                                                addtocartfunctionGrid(product._id, e);
                                                                navigate('/checkout');
                                                            }}
                                                            _hover={{ bg: "black", color: "white" }}
                                                            rightIcon={<FaShoppingCart />}
                                                        >
                                                            Buy Now
                                                        </Button>
                                                    </Stack>




                                                    </Flex>
                                                </CardFooter>
                                            </Card>
                                        </Box>
                                    )) : <Box justifyContent="center" fontWeight="bold" m="auto">
                                        <Text fontSize="22px">No items Found</Text>
                                        <DogsComponent />
                                    </Box>}
                                </SimpleGrid>
                            </>
                        }
                        {
                            loading ? <>   <Flex width={"100%"} ml={["", "", "100%"]} direction={"column"}>
                                <Flex width="100%" direction="column" alignItems="center" justifyContent="center" minHeight="100vh">
                                    <video autoPlay loop muted width="700">
                                        <source src={anim} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </Flex>
                            </Flex></> : <>
                                {/* List view */}
                                <Flex direction={'column'} display={showStyle === 'list' ? ['none', 'none', 'flex'] : 'none'} gap={5}>
                                    {products.length > 0 && currentProducts.length > 0 ? currentProducts.map((product) => (
                                        <Box display={"flex"} key={product._id}>
                                            <Card border={'1px solid lightgray'} borderRadius={'5px'}>
                                                <Box>
                                                    <CardBody display={"flex"} onClick={() => handleCardClick(product._id)}>
                                                        <Image maxH={"250px"} w={'250px'} _hover={{ cursor: "pointer" }} src={product?.photos?.[0] || ''} alt={product.title} borderRadius="lg"
                                                            onClick={() => {
                                                                const recentlyViewedProducts = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
                                                                const updatedRecentlyViewed = [...recentlyViewedProducts, product._id];
                                                                const uniqueRecentlyViewed = [...new Set(updatedRecentlyViewed)];
                                                                localStorage.setItem('recentlyViewed', JSON.stringify(uniqueRecentlyViewed));
                                                                navigate(`/products/${product._id}`);
                                                            }}
                                                        />
                                                        <Stack ml={"2%"} w={"40%"} mt="6">
                                                            <Tooltip content={product.title}>
                                                                <Text m={0} fontSize={['18px', '20px']} fontWeight={'600'}>
                                                                    {product.title.split(' ').slice(0, 12).join(' ')}...
                                                                </Text>
                                                            </Tooltip>
                                                            <Text m={0} fontSize={'16px'}>
                                                                {product.qtyInStock <= 10 ? `Hurry up! Only ${product.qtyInStock} left in stock` : `${product.qtyInStock} in stock. Order now!`}
                                                            </Text>
                                                            <Flex align="center">
                                                                {currencyIcons[userCurrency]}
                                                                <Text fontSize={'18px'} m={0} ml={1}>
                                                                    {product[`price${userCurrency}`]}
                                                                </Text>
                                                            </Flex>
                                                        </Stack>
                                                        <Stack m={"auto"} textAlign={"center"} alignContent={"center"}>
                                                            <Button
                                                                colorScheme="green"
                                                                bg="#e8eee4"
                                                                color="#6FA82F"
                                                                borderRadius={0}
                                                                mt={4}
                                                                mb={4}
                                                                _hover={{ bg: '#6FA82F', color: 'white' }}
                                                                rightIcon={<FaShoppingCart />}
                                                                onClick={(e) => {
                                                                    addtocartfunctionGrid(product._id, e);
                                                                }}
                                                            >
                                                                Add to Cart
                                                            </Button>
                                                        </Stack>
                                                    </CardBody>
                                                </Box>
                                                <Box>
                                                    <Flex w={''} justifyContent={'center'}>
                                                        <Flex p={2} direction={"column"} alignItems={"center"} gap={5} position={"absolute"} top={0} right={0}>
                                                            <Button
                                                                _hover={{ transform: "scale(1.3)", transition: "transform 0.3s ease-in-out" }}
                                                                size={'md'}
                                                                borderRadius={'50%'}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    addToWishlistFunction(product._id);
                                                                }}
                                                            >
                                                                <FaHeart color="rgb(111,168,47)" />
                                                            </Button>
                                                            <ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} />

                                                        </Flex>
                                                    </Flex>
                                                </Box>
                                            </Card>
                                        </Box>
                                    )) : <Box justifyContent="center" fontWeight="bold" m="auto">
                                        <Text fontSize="22px">No items Found</Text>
                                        <DogsComponent />
                                    </Box>}
                                </Flex>
                            </>
                        }
                        {/* Pagination */}
                        <Flex mt={5} justify="center" flexWrap={"wrap"}>
                            {Array.from({ length: Math.ceil(products.length / ITEMS_PER_PAGE) }).map((_, index) => (
                                <Button
                                    mt={2}
                                    key={index}
                                    size="md"
                                    borderRadius={0}
                                    mx={1}
                                    onClick={() => paginate(index + 1)}
                                    colorScheme={currentPage === index + 1 ? 'green' : 'gray'}
                                >
                                    {index + 1}
                                </Button>
                            ))}
                        </Flex>
                    </Box>
                </Flex>
            </Flex >
        </>
    );
};
export default AllProducts;
