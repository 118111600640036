import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Flex, Text, Button, Link } from "@chakra-ui/react";
import { FaCookie, FaCheck, FaTimes } from "react-icons/fa";
import TopBanner from "../components/HomePageComponents/TopBanner";
import Footer from "../components/HomePageComponents/Footer";
import MostViewProducts from "../components/HomePageComponents/MostViewProducts";
import Carousel from "../components/HomePageComponents/Carousel";
import SpinWheelModal from "../components/SpinWheelModal";
import ImageGrid from "../components/HomePageComponents/ImageGrid";
import LogoFeatures from "../components/HomePageComponents/LogoFeatures";
import Nav from "../components/HomePageComponents/Nav";
import Bestseller from "../components/HomePageComponents/Bestseller";
import FeaturedProducts from "../components/HomePageComponents/FeaturedProducts";
import NewArrivals from "../components/HomePageComponents/NewArrivals";
import UserChatButton from '../components/Chat/ChatButton'
import RecentlyViewed from "../components/HomePageComponents/RecentlyViewed";
import BigOrderUi from "../components/Checkout.jsx/BigOrderUi";

const CookieConsentBanner = ({ onAcceptAll }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowBanner(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem("cookieConsent", "true");
    onAcceptAll();
  };


  return (
    showBanner && (
      <Flex
        position="fixed"
        bottom="0"
        width="100%"
        bg={"rgb(246,250,242)"}
        color="#6FA82F"
        p="4"
        borderRadius={"md"}
        justifyContent="space-between"
        alignItems="center"
        zIndex="1000"
        boxShadow="0 -4px 6px rgba(0, 0, 0, 0.1)"
      >
        <Box display="flex" alignItems="center">
          <FaCookie size={40} style={{ marginRight: "20px" }} />
          <Text color="black">
            We use cookies to improve your experience on our site. By using our site, you consent to cookies.{" "}
            <Link href="/privacy-policy" color="#6FA82F" textDecoration="underline">
              Learn more
            </Link>
          </Text>
        </Box>
        <Box>
          <Button
            colorScheme="red"
            variant="outline"
            size="sm"
            onClick={handleAcceptAll}
            leftIcon={<FaTimes />}
            mr="2"
            _hover={{ bg: "red.500", color: "white" }}
          >
            Reject All
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            bg="#6FA82F"
            onClick={handleAcceptAll}    
            leftIcon={<FaCheck />}
            _hover={{ bg: "#4d7c0e" }}
          >
            Accept All
          </Button>
        </Box>
      </Flex>
    )
  );
};

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(() => {
    return localStorage.getItem("cookieConsent") === "true";
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setCookieConsent(true);
  };

  return (
    <Box>
      <Helmet>
        <meta name="KnitSilk | Crafting Luxury | Home" content="KnitSilk | Crafting Luxury" />
        <title>KnitSilk | Crafting Luxury</title>
      </Helmet>
      <TopBanner />
      <Nav />
      <Carousel />
      <ImageGrid />
      <BigOrderUi/>
      <Bestseller />
      <FeaturedProducts />
      <NewArrivals />
      <MostViewProducts />
      <RecentlyViewed />
      <LogoFeatures />
      <UserChatButton />
      <Footer />
      {showModal && <SpinWheelModal onClose={() => setShowModal(false)} />}
      {!cookieConsent && <CookieConsentBanner onAcceptAll={handleAcceptCookies} />}
    </Box>
  );
};

export default HomePage;
