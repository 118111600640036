import { Box, Flex, SimpleGrid, Text, Input, Button, Card, CardBody, Image, Stack, Divider, ModalBody, ModalFooter, ModalHeader, CardFooter, Checkbox, Menu, MenuButton, Spinner, Modal, MenuList, MenuItem, ModalOverlay, ModalCloseButton, InputGroup, FormControl, FormLabel, InputRightAddon, ModalContent, useDisclosure, HStack, Select, Textarea } from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react';
import AdminNav from './AdminNav';
import ListingRightNav from './ListingRightNav';
import ListingRightNavPhone from './ListingRightNavPhone';
import anim from '../../assets/LOADING.mp4';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon, EditIcon, SettingsIcon, TriangleDownIcon } from '@chakra-ui/icons';
import * as XLSX from 'xlsx';
import ShareButton from "../ProductsPageComponents/ShareButton"
import { AppContext } from '../../Context/AppContext';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;


const Listing = () => {
  let { refresh, setRefresh } = useContext(AppContext)
  const [showStyle, setshowStyle] = useState("grid");
  const [temp, settemp] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [editedTitle, setEditedTitle] = useState('');
  const [cat, setCat] = useState([]);
  const {
    isOpen: isTitleModalOpen,
    onOpen: openTitleModal,
    onClose: closeTitleModal,
  } = useDisclosure();

  const [editedCategory, setEditedCategory] = useState('');
  const {
    isOpen: isCategoryModalOpen,
    onOpen: openCategoryModal,
    onClose: closeCategoryModal,
  } = useDisclosure();

  const [editedPriceUSD, setEditedPriceUSD] = useState('');
  const {
    isOpen: isPriceUSDModalOpen,
    onOpen: openPriceUSDModal,
    onClose: closePriceUSDModal,
  } = useDisclosure();

  const [editedPriceINR, setEditedPriceINR] = useState('');
  const {
    isOpen: isPriceINRModalOpen,
    onOpen: openPriceINRModal,
    onClose: closePriceINRModal,
  } = useDisclosure();

  const [editedPriceGBP, setEditedPriceGBP] = useState('');
  const {
    isOpen: isPriceGBPModalOpen,
    onOpen: openPriceGBPModal,
    onClose: closePriceGBPModal,
  } = useDisclosure();

  const [editedPriceEUR, setEditedPriceEUR] = useState('');
  const {
    isOpen: isPriceEURModalOpen,
    onOpen: openPriceEURModal,
    onClose: closePriceEURModal,
  } = useDisclosure();

  const [editedPriceJPY, setEditedPriceJPY] = useState('');
  const {
    isOpen: isPriceJPYModalOpen,
    onOpen: openPriceJPYModal,
    onClose: closePriceJPYModal,
  } = useDisclosure();

  const [editedPriceCAD, setEditedPriceCAD] = useState('');
  const {
    isOpen: isPriceCADModalOpen,
    onOpen: openPriceCADModal,
    onClose: closePriceCADModal,
  } = useDisclosure();

  const [editedPriceAUD, setEditedPriceAUD] = useState('');
  const {
    isOpen: isPriceAUDModalOpen,
    onOpen: openPriceAUDModal,
    onClose: closePriceAUDModal,
  } = useDisclosure();


  const [editedDescription, setEditedDescription] = useState('');
  const {
    isOpen: isDescriptionModalOpen,
    onOpen: openDescriptionModal,
    onClose: closeDescriptionModal,
  } = useDisclosure();


  const [editedQty, setEditedQty] = useState('');
  const {
    isOpen: isQtyModalOpen,
    onOpen: openQtyModal,
    onClose: closeQtyModal,
  } = useDisclosure();

  const [editedTags, setEditedTags] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const {
    isOpen: isTagsModalOpen,
    onOpen: openTagsModal,
    onClose: closeTagsModal,
  } = useDisclosure();





  let filterbysearch = (e) => {

    if (e == "") {
      getdata();
      setSearchTerm("")
    } else {
      let filteredProducts = products.filter(product =>
        product.title.toLowerCase().includes(e.toLowerCase()) ||
        product.sku.toLowerCase().includes(e.toLowerCase())
      )

      settemp(filteredProducts);
      setProducts(filteredProducts);
      setSearchTerm(e);
    }
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500); // 1000 milliseconds = 1 second
  };

  const handleDelete = async (productId) => {
    const isConfirmed = await showConfirmationAlert('Delete Product', 'Are you sure you want to delete this product?');

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error deleting product:', errorData.error);
        showAlert('Error', 'Failed to delete the product', 'error');
      } else {
        // Remove the deleted product from the state
        setProducts(prevProducts => prevProducts.filter(product => product._id !== productId));
        settemp(prevProducts => prevProducts.filter(product => product._id !== productId));
        showAlert('Success', 'Product deleted successfully', 'success');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleDeleteMultiple = async () => {
    const isConfirmed = await showConfirmationAlert('Delete Products', 'Are you sure you want to delete selected products?');

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch('https://api.digiblocks.tech/products/delete-multiple', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productIds: selectedProductIds }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error deleting multiple products:', errorData.error);
        showAlert('Error', 'Failed to delete multiple products', 'error');
      } else {
        // Remove the deleted products from the state
        settemp(prevProducts => prevProducts.filter(product => !selectedProductIds.includes(product._id)));
        settemp(prevProducts => prevProducts.filter(product => !selectedProductIds.includes(product._id)));
        settemp(prevProducts => prevProducts.filter(product => !selectedProductIds.includes(product._id)));
        setProducts(prevProducts => prevProducts.filter(product => !selectedProductIds.includes(product._id)));
        // Clear the selected product IDs
        setSelectedProductIds([]);
        showAlert('Success', 'Products deleted successfully', 'success');
      }
    } catch (error) {
      console.error('Error deleting multiple products:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const showConfirmationAlert = async (title, text) => {
    const { value: isConfirmed } = await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    return isConfirmed;
  };
  const showAlert = (title, message, type) => {
    Swal.fire({
      title,
      text: message,
      icon: type,
    });
  };
  const handleProductSelect = (productId) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds(prevSelected => prevSelected.filter(id => id !== productId));
    } else {
      setSelectedProductIds(prevSelected => [...prevSelected, productId]);
    }
  };
  const handleSelectAll = () => {
    // If all products are already selected, clear the selection; otherwise, select all
    if (selectedProductIds.length === products.length) {
      setSelectedProductIds([]);
    } else {
      const allProductIds = products.map(product => product._id);
      setSelectedProductIds(allProductIds);
    }
  };
  const handleEditTitles = () => {
    openTitleModal();
    setEditedTitle('');
  };
  const handleSaveTitles = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title: editedTitle }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeTitleModal(); // Close the title modal
      showAlert('Success', 'Titles updated successfully', 'success');
    } catch (error) {
      console.error('Error updating titles:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };


  const handleEditCategories = () => {
    openCategoryModal();
    setEditedCategory('');
  };

  const handleSaveCategories = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ category: editedCategory }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating Category for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update Category for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeCategoryModal(); // Close the title modal
      showAlert('Success', 'Categories updated successfully', 'success');
    } catch (error) {
      console.error('Error updating Categories:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  //Price-edit---------------------------------------------------------------------------->

  const handleEditPricesUSD = () => {
    openPriceUSDModal();
    setEditedPriceUSD('');
  };
  const handleEditPricesINR = () => {
    openPriceINRModal();
    setEditedPriceINR('');
  }
  const handleEditPricesGBP = () => {
    openPriceGBPModal();
    setEditedPriceGBP('');
  }
  const handleEditPricesEUR = () => {
    openPriceEURModal();
    setEditedPriceEUR('');
  }
  const handleEditPricesJPY = () => {
    openPriceJPYModal();
    setEditedPriceJPY('');
  }
  const handleEditPricesCAD = () => {
    openPriceCADModal();
    setEditedPriceCAD('');
  }
  const handleEditPricesAUD = () => {
    openPriceAUDModal();
    setEditedPriceAUD('');
  }

  const handleSavePricesUSD = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceUSD: editedPriceUSD }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closePriceUSDModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleSavePricesINR = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceINR: editedPriceINR }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceINRModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleSavePricesGBP = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceGBP: editedPriceGBP }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceGBPModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleSavePricesEUR = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceEUR: editedPriceEUR }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceEURModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleSavePricesJPY = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceJPY: editedPriceJPY }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceJPYModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };
  const handleSavePricesCAD = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceCAD: editedPriceCAD }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.Fjson();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceCADModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  const handleSavePricesAUD = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ priceAUD: editedPriceAUD }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      closePriceAUDModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  //Description-edit---------------------------------------------------------------------------->

  const handleEditDescriptions = () => {
    openDescriptionModal();
    setEditedDescription(''); // Reset edited title
  };

  const handleSaveDescriptions = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ description: editedDescription }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeDescriptionModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  //Qty-edit---------------------------------------------------------------------------->

  const handleEditQtys = () => {
    openQtyModal();
    setEditedQty(''); // Reset edited title
  };

  const handleSaveQtys = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ qtyInStock: editedQty }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeQtyModal(); // Close the title modal
      showAlert('Success', 'Prices updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  //Tags-edit---------------------------------------------------------------------------->

  const handleEditTags = () => {
    openTagsModal();
    // Initialize edited tags with the current tags of selected products
    const selectedProductsTags = selectedProductIds.reduce((tags, productId) => {
      const product = products.find((p) => p._id === productId);
      if (product && product.tags) {
        tags.push(...product.tags);
      }
      return tags;
    }, []);
    // Remove duplicate tags and join as a comma and space-separated string
    setEditedTags(selectedProductsTags.filter((tag, index, self) => self.indexOf(tag) === index).join(', '));
  };
  const handleSaveTags = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ tags: editedTags.split(',').map(tag => tag.trim()) }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating tags for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update tags for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );

      // Update the state with the updated products
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeTagsModal(); // Close the tags modal
      showAlert('Success', 'Tags updated successfully', 'success');
    } catch (error) {
      console.error('Error updating Tags:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  const downloadAllProductsAsExcel = () => {
    const sheetData = products.map(product => [
      product.title,
      product.priceINR,
      product.priceUSD,
      product.color,
      product.yarnWeight,
      product.listingStatus,
      product.pcs,
      product.qtyInStock,
      product.category,
      product.tags.join(', '),
      product.material,
      product.deliveryOption,
      product.itemWeight,
      product.itemSize,
      product.manufacturer,
      product.productDimensions,
      product.technicalDetails,
      product.size,
      product.primaryMaterial,
      product.makeContent,
      product.care,
      product.yardage,
      product.needleSize,
      product.hookSize,
      product.dateAdded,
    ]);


    const worksheet = XLSX.utils.aoa_to_sheet([
      ['Title', 'Price', 'Global Price', 'Color', 'Yarn Weight', 'Listing Status', 'PCS', 'Qty In Stock', 'Category', 'Tags', 'Material', 'Delivery Option', 'Item Weight', 'Item Size', 'Manufacturer', 'Product Dimensions', 'Technical Details', 'Size', 'Primary Material', 'Make Content', 'Care', 'Yardage', 'Needle Size', 'Hook Size', 'Date Added'],
      ...sheetData
    ]);


    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

    XLSX.writeFile(workbook, 'products.xlsx');
  };

  //---->SALE------------------------------------------------------------------->>>>>>>
  const [saleName, setSaleName] = useState('');
  const [saleStartDate, setSaleStartDate] = useState('');
  const [saleEndDate, setSaleEndDate] = useState('');
  const [editedOffer, setEditedOffer] = useState('');
  const {
    isOpen: isOfferModalOpen,
    onOpen: openOfferModal,
    onClose: closeOfferModal,
  } = useDisclosure();

  const handleEditBulkOffer = async () => {
    openOfferModal();
    setEditedOffer('');
  }

  const handleSaveOffer = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ discount: editedOffer, discountTitle: saleName, saleStartDate: saleStartDate, saleEndDate: saleEndDate }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating title for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update title for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          updatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeOfferModal();
      showAlert('Success', 'Sale updated successfully', 'success');
    } catch (error) {
      console.error('Error updating prices:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  // Personalization
  const [editedPersonalization, setEditedPersonalization] = useState('');
  const {
    isOpen: isPersonalizationModalOpen,
    onOpen: openPersonalizationModal,
    onClose: closePersonalizationModal,
  } = useDisclosure();

  const handleEditPersonalization = () => {
    openPersonalizationModal();
    setEditedPersonalization('');
  }

  const handleSavePersonalization = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ personalization: editedPersonalization === 'true' }),
            // Convert the editedPersonalization to a boolean value
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating personalization for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update personalization for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );
      const filteredUpdatedProducts = updatedProducts.filter((product) => product !== undefined);

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          filteredUpdatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          filteredUpdatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closePersonalizationModal();
      showAlert('Success', 'Personalization updated successfully', 'success');
    } catch (error) {
      console.error('Error updating personalization:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };

  //Section (HomePage Section);

  const [editedSection, setEditedSection] = useState('');
  const {
    isOpen: isSectionModalOpen,
    onOpen: openSectionModal,
    onClose: closeSectionModal,
  } = useDisclosure();

  const handleEditSection = () => {
    openSectionModal();
    setEditedSection('');
  }

  const handleSaveSection = async () => {
    try {
      const updatedProducts = await Promise.all(
        selectedProductIds.map(async (productId) => {
          const response = await fetch(`https://api.digiblocks.tech/products/${productId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ shopSection: editedSection }),
          });

          if (!response.ok) {
            const errorData = await response.json();
            console.error(`Error updating section for product ${productId}:`, errorData.error);
            showAlert('Error', `Failed to update homepage section for product ${productId}`, 'error');
          } else {
            const updatedProduct = await response.json();
            return updatedProduct;
          }
        })
      );
      const filteredUpdatedProducts = updatedProducts.filter((product) => product !== undefined);

      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          filteredUpdatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );
      settemp((prevProducts) =>
        prevProducts.map((product) =>
          filteredUpdatedProducts.find((updatedProduct) => updatedProduct._id === product._id) || product
        )
      );

      closeSectionModal();
      showAlert('Success', 'HomePage updated successfully', 'success');
    } catch (error) {
      console.error('Error updating personalization:', error);
      showAlert('Error', 'An unexpected error occurred', 'error');
    }
  };



  let ITEMS_PER_PAGE = 30;
  const indexOfLastProduct = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstProduct = indexOfLastProduct - ITEMS_PER_PAGE;
  let mappingProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  let getdata = async () => {
    fetch('https://api.digiblocks.tech/products')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
        settemp(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        setLoading(false);
      });
  }
  useEffect(() => {
    getdata();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.digiblocks.tech/adminsetting/categories');
      const categoriesData = response.data;
      setCat(categoriesData);
    } catch (error) {
      console.error('Error fetching Categories:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    setCurrentPage(1);
  }, [refresh])

  return (
    <>
      {loading && (
        <Flex
          justify="center"
          align="center"
          w="100%"
          h="100vh"
          position="fixed"
          top="0"
          left="0"
          bg="rgba(255, 255, 255, 0.8)"
          zIndex="1000"
        >
          <video autoPlay loop muted width="700">
            <source src={anim} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Flex>
      )}
      <Flex direction={["column", "column", "row"]}>
        <AdminNav />
        <Flex ml={["0px", "0px", "15%"]} w={"100%"} flex={"6"}>
          <Box flex={["6", "4"]} p={3} borderRight={"1px solid teal"}>
            <Box
            // sx={{
            //   position: 'sticky',
            //   top: '0',
            //   backgroundColor: 'white',
            //   zIndex: '1000',
            // }}
            >
              <Flex direction={["column", "column", "row"]} alignItems={"center"} justifyContent={"space-between"} p={2}>
                <Text fontSize={"22px"} fontWeight={"500"}>Listings</Text>
                <Text fontSize={"18px"} fontWeight={"500"}>{products.length}{" "}Products</Text>
                <Flex direction={["column", "column", "row"]} alignItems={"center"} gap={3}>
                  <Input
                    w={["100%", "100%", "70%"]}
                    borderRadius={"0px"}
                    border={"1px solid teal"}
                    placeholder="Search By Title/Tag/SKU..."
                    onChange={(e) => {
                      filterbysearch(e.target.value)
                    }}
                  />
                  <Button
                    size={"md"}
                    bg={"black"}
                    colorScheme='black'
                    border={"1px solid teal"}
                    color={"white"}
                    borderRadius={0}
                    onClick={downloadAllProductsAsExcel}
                  >
                    Export to Excel
                  </Button>

                  <Button size={"md"} bg={"black"} border={"1px solid teal"} colorScheme='black' color={"white"} borderRadius={0} onClick={() => navigate("/manager/addnewlisting")}>
                    Add a Listing
                  </Button>
                  <ListingRightNavPhone products={products} setCurrentPage={setCurrentPage} setProducts={setProducts} setshowStyle={setshowStyle} />

                </Flex>
              </Flex>
              <Divider></Divider>
              <Flex gap={"3"} border={"1px solid teal"} p={3} direction={["column", "column", "row"]} justifyContent={"flex-start"} pl={4}>
                <Flex>
                  <Text m={"auto"} fontWeight={"bold"}>Manage Listings</Text>
                </Flex>
                <Box border={"1px solid teal"}></Box>
                <Flex border={"1px solid teal"} borderRadius={"md"} p={2} gap={2} alignItems={"center"}>
                  <Checkbox isChecked={selectedProductIds.length === products.length}
                    onChange={handleSelectAll} colorScheme='green'></Checkbox>
                  <Text m={"auto"} fontWeight={"bolder"}>{selectedProductIds.length}</Text>
                  <TriangleDownIcon />
                </Flex>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    isDisabled={selectedProductIds.length === 0}
                    _hover={{ bg: '#6FA82F', color: 'white' }}
                  >
                    Edit Options <span></span><TriangleDownIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={handleEditTitles}>Edit Titles</MenuItem>
                    <MenuItem onClick={handleEditCategories}>Edit Categories</MenuItem>
                    <MenuItem onClick={handleEditPricesUSD}>Edit Prices in USD</MenuItem>
                    <MenuItem onClick={handleEditPricesINR}>Edit Prices INR</MenuItem>
                    <MenuItem onClick={handleEditPricesGBP}>Edit Prices GBP</MenuItem>
                    <MenuItem onClick={handleEditPricesEUR}>Edit Prices EUR</MenuItem>
                    <MenuItem onClick={handleEditPricesJPY}>Edit Prices JPY</MenuItem>
                    <MenuItem onClick={handleEditPricesCAD}>Edit Prices CAD</MenuItem>
                    <MenuItem onClick={handleEditPricesAUD}>Edit Prices AUD</MenuItem>
                    <MenuItem onClick={handleEditDescriptions}>Edit Descriptions</MenuItem>
                    <MenuItem onClick={handleEditPersonalization}>Edit Personalization</MenuItem>
                    <MenuItem onClick={handleEditSection}>Edit HomePage Section</MenuItem>
                    <MenuItem onClick={handleEditTags}>Edit Tags</MenuItem>
                    <MenuItem onClick={handleEditQtys}>Edit Quantity In Stock</MenuItem>
                  </MenuList>
                </Menu>
                <Button
                  variant={"outline"}
                  _hover={{ bg: "#6FA82F", color: "white" }}
                  color="#6FA82F"
                  onClick={handleDeleteMultiple}
                  isDisabled={selectedProductIds.length === 0}
                >
                  Delete
                </Button>
                <Button
                  variant={"outline"}
                  _hover={{ bg: "#6FA82F", color: "white" }}
                  color="#6FA82F"
                  onClick={handleEditBulkOffer}
                  isDisabled={selectedProductIds.length === 0}
                >
                  Run A Sale
                </Button>
              </Flex>
              <Divider></Divider>
            </Box>

            {/* Modal for editing titles */}
            <Modal isOpen={isTitleModalOpen} onClose={closeTitleModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Titles of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Title:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveTitles}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeTitleModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing Categories */}
            <Modal isOpen={isCategoryModalOpen} onClose={closeCategoryModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Category of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Category:</FormLabel>
                    <InputGroup>
                      <Select
                        value={editedCategory}
                        onChange={(e) => setEditedCategory(e.target.value)}
                      >
                        <option value="">Select A Category </option>
                        {cat.map((color) => (
                          <option key={color} value={color.categories}>{color.categories.charAt(0).toUpperCase() + color.categories.slice(1)}</option>
                        ))}
                      </Select>
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveCategories}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeCategoryModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing USD prices */}
            <Modal isOpen={isPriceUSDModalOpen} onClose={closePriceUSDModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceUSD}
                        onChange={(e) => setEditedPriceUSD(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesUSD}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceUSDModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  INR*/}
            <Modal isOpen={isPriceINRModalOpen} onClose={closePriceINRModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit INR Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in INR:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceINR}
                        onChange={(e) => setEditedPriceINR(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesINR}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceINRModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing   Personalization*/}
            <Modal isOpen={isPersonalizationModalOpen} onClose={closePersonalizationModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Personalization of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Allow Personalization (YES/NO):</FormLabel>
                    <InputGroup>
                      <Select
                        value={editedPersonalization}
                        onChange={(e) => setEditedPersonalization(e.target.value)}
                      >
                        <option value="true">YES</option>
                        <option value="false">NO</option>
                      </Select>
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePersonalization}>
                          Save
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePersonalizationModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing   Section*/}
            <Modal isOpen={isSectionModalOpen} onClose={closeSectionModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit/Change HomePage of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <InputGroup>
                      <Select
                        value={editedSection}
                        onChange={(e) => setEditedSection(e.target.value)}
                      >
                        <option value="">Select A Shop Section</option>
                        <option value="BestSellers">Best Sellers - Loved by all </option>
                        <option value="Featured">Featured - Crafted for you</option>
                        <option value="NewArrivals">New Arrivals - New & now</option>
                        <option value="MostViewed">Most Viewed - Seen by many</option>
                        <option value="SpotlightDeals">Spotlight Deals
                        </option>
                      </Select>
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveSection}>
                          Save
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeSectionModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  GBP*/}
            <Modal isOpen={isPriceGBPModalOpen} onClose={closePriceGBPModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit GBP Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in GBP:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceGBP}
                        onChange={(e) => setEditedPriceGBP(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesGBP}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceGBPModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  EUR*/}
            <Modal isOpen={isPriceEURModalOpen} onClose={closePriceEURModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit EUR Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in EUR:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceEUR}
                        onChange={(e) => setEditedPriceEUR(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesEUR}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceEURModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  JPY*/}
            <Modal isOpen={isPriceJPYModalOpen} onClose={closePriceJPYModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit JPY Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in JPY:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceJPY}
                        onChange={(e) => setEditedPriceJPY(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesJPY}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceJPYModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  CAD*/}
            <Modal isOpen={isPriceCADModalOpen} onClose={closePriceCADModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit CAD Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in CAD:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceCAD}
                        onChange={(e) => setEditedPriceCAD(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesCAD}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceCADModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing prices  AUD*/}
            <Modal isOpen={isPriceAUDModalOpen} onClose={closePriceAUDModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit AUD Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Price in AUD:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedPriceAUD}
                        onChange={(e) => setEditedPriceAUD(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSavePricesAUD}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closePriceAUDModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing Descriptions */}
            <Modal isOpen={isDescriptionModalOpen} onClose={closeDescriptionModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Prices of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Description:</FormLabel>
                    <InputGroup>
                      <Textarea
                        type="text"
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveDescriptions}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeDescriptionModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing QTY */}
            <Modal isOpen={isQtyModalOpen} onClose={closeQtyModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Quantity of Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Quantity In Stock:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedQty}
                        onChange={(e) => setEditedQty(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveQtys}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeQtyModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* Modal for editing TAGS */}
            <Modal isOpen={isTagsModalOpen} onClose={closeTagsModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Tags Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>New Tags:</FormLabel>
                    <InputGroup>
                      <Input
                        type="text"
                        value={editedTags}
                        onChange={(e) => setEditedTags(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveTags}>Save</Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeTagsModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal isOpen={isOfferModalOpen} onClose={closeOfferModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Apply Bulk Offer For Selected Products</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl>
                    <FormLabel>Sale Name:</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter sale name"
                      value={saleName}
                      onChange={(e) => setSaleName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Sale Duration:</FormLabel>
                    <HStack>
                      <Input
                        type="date"
                        value={saleStartDate}
                        onChange={(e) => setSaleStartDate(e.target.value)}
                      />
                      <Text>-</Text>
                      <Input
                        type="date"
                        value={saleEndDate}
                        onChange={(e) => setSaleEndDate(e.target.value)}
                      />
                    </HStack>
                  </FormControl>

                  <FormControl mt={4}>
                    <FormLabel>New Offer(%):</FormLabel>
                    <InputGroup>
                      <Input
                        type="number"
                        value={editedOffer}
                        onChange={(e) => setEditedOffer(e.target.value)}
                      />
                      <InputRightAddon bg="green">
                        <Button bg="green" size={"sm"} color={"white"} onClick={handleSaveOffer}>
                          Save
                        </Button>
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button bg="red" size={"sm"} color={"white"} onClick={closeOfferModal}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>


            {/* Grid view */}
            <SimpleGrid display={showStyle === "grid" ? "grid" : "none"} columns={[1, 1, 3]} spacing='40px'>
              {mappingProducts && mappingProducts.map(product => (
                <Box key={product._id}>
                  <Card maxW='sm' border={"1px solid teal"} borderRadius={"5px"}>
                    <CardBody>
                      <Image
                        w={"100%"}
                        h={"200px"}
                        src={product?.photos[0]}
                        alt={product.title}
                        borderRadius='lg'
                      />
                      <Stack mt='6'>
                        <Text m={0} textDecoration="underline" onClick={() => navigate(`/manager/listing/${product._id}`)} fontSize={["18px", "20px"]} fontWeight={"600"}>
                          {product.title.split(' ').slice(0, 9).join(' ')}
                        </Text>

                        <Text m={0} fontSize={"14px"}>
                          {product.qtyInStock} in Stock
                        </Text>
                        <Text fontSize={"14px"} m={0}>
                          {`Rs.${product.priceINR} in India`}
                        </Text>
                        <Text fontSize={"14px"} m={0}>
                          {`$${product.priceUSD} in Everywhere Else`}
                        </Text>
                        {product.discount && product.discount > 0 && (
                          <Text fontSize={"14px"} fontWeight={"semibold"} color={"green"} m={0}>
                            {`${product.discount}% off`}
                          </Text>
                        )}

                      </Stack>
                    </CardBody>

                    <CardFooter borderTop={"1px solid teal"}>
                      <Flex w={"100%"} justifyContent={"space-between"}>
                        <Checkbox
                          colorScheme='green'
                          isChecked={selectedProductIds.includes(product._id)}
                          onChange={() => handleProductSelect(product._id)}
                        />
                        <EditIcon
                          m={"auto"}
                          onClick={() => window.open(`/manager/listings/edit/${product._id}`, "_blank")}
                          _hover={{ color: "#6FA82F" }}

                        />

                        <DeleteIcon
                          m={"auto"}
                          _hover={{ color: "#6FA82F" }}
                          onClick={() => handleDelete(product._id)}
                        />

                        <Menu>
                          <MenuButton as={Button} bg={"none"} borderRadius={"none"} pr={6} _hover={{ bg: "#6FA82F", color: "white" }} leftIcon={<SettingsIcon />}>
                            <TriangleDownIcon />
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => window.open(`/products/${product._id}`, '_blank')}>View on KnitSilk</MenuItem>
                            <MenuItem onClick={() => window.open(`/manager/listings/edit/${product._id}`, "_blank")}>Edit</MenuItem>
                            <MenuItem onClick={() => navigate(`/manager/listings/copy/${product._id}`)}>Copy</MenuItem>
                            <MenuItem onClick={() => navigate(`/manager/listings/variation/${product._id}`)}>Add Variations</MenuItem>
                            <MenuItem onClick={() => handleDelete(product._id)}>Delete</MenuItem>
                            <MenuItem>Share<ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} /></MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                    </CardFooter>
                  </Card>
                </Box>
              ))}
            </SimpleGrid>

            {/* List view */}
            <Flex direction={"column"} display={showStyle === "list" ? "flex" : "none"} gap={5}>
              {mappingProducts && mappingProducts.map(product => (
                <Box key={product._id}>
                  <Card border={"1px solid lightgray"} borderRadius={"5px"}>
                    <CardBody>
                      <Flex gap={5} direction={["column", "column", "row"]}>
                        <Image
                          w={"200px"}
                          src={product.photos[0]}
                          alt={product.title}
                          borderRadius='lg'
                        />
                        <Stack mt='6'>
                          <Text m={0} textDecoration="underline" fontSize={["18px", "20px"]} fontWeight={"600"}>
                            {product.title.split(' ').slice(0, 9).join(' ')}
                          </Text>

                          <Text m={0} fontSize={"14px"}>
                            {`${product.qtyInStock} in Stock`}
                          </Text>
                          <Text fontSize={"14px"} m={0}>
                            {`Rs.${product.priceINR} in India`}
                          </Text>
                          <Text fontSize={"14px"} m={0}>
                            {`$${product.priceUSD} in Everywhere Else`}
                          </Text>
                        </Stack>
                      </Flex>
                    </CardBody>

                    <CardFooter borderTop={" 1px solid teal"}>
                      <Flex w={"100%"} justifyContent={"flex-end"} gap={5}>
                        <Checkbox
                          colorScheme='green'
                          isChecked={selectedProductIds.includes(product._id)}
                          onChange={() => handleProductSelect(product._id)}
                        />
                        <EditIcon
                          m={"auto"}
                          onClick={() => window.open(`/manager/listings/edit/${product._id}`, "_blank")}
                          _hover={{ color: "#6FA82F" }}
                        />

                        <DeleteIcon
                          m={"auto"}
                          _hover={{ color: "#6FA82F" }}
                          onClick={() => handleDelete(product._id)}
                        />

                        <Menu>
                          <MenuButton as={Button} bg={"none"} borderRadius={"none"} pr={6} _hover={{ bg: "#6FA82F", color: "white" }} leftIcon={<SettingsIcon />}>
                            <TriangleDownIcon />
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => window.open(`/products/${product._id}`, '_blank')}>View on KnitSilk</MenuItem>
                            <MenuItem onClick={() => window.open(`/manager/listings/edit/${product._id}`, "_blank")}>Edit</MenuItem>
                            <MenuItem onClick={() => navigate(`/manager/listings/copy/${product._id}`)}>Copy</MenuItem>
                            <MenuItem onClick={() => navigate(`/manager/listings/variation/${product._id}`)}>Add Variations</MenuItem>
                            <MenuItem onClick={() => handleDelete(product._id)}>Delete</MenuItem>
                            <MenuItem>Share<ShareButton shareUrl={`${baseUrl}/products/${product._id}`} shareTitle={product.title} /></MenuItem>
                          </MenuList>
                        </Menu >
                      </Flex >
                    </CardFooter >
                  </Card >
                </Box >
              ))}
            </Flex >

            {/* Pagination */}
            <Flex mt={5} justify="center" flexWrap={"wrap"}>
              {Array.from({ length: Math.ceil(products.length / ITEMS_PER_PAGE) }).map((_, index) => (
                <Button
                  mt={2}
                  key={index}
                  size="md"
                  borderRadius={0}
                  mx={1}
                  onClick={() => paginate(index + 1)}
                  colorScheme={currentPage === index + 1 ? 'green' : 'gray'}
                >
                  {index + 1}
                </Button>
              ))}
            </Flex>
          </Box >
          <Box flex={"1"} p={3} display={["none", "none", "block"]} boxShadow={"0 0 3px gray"}>
            <ListingRightNav products={products} setCurrentPage={setCurrentPage} setProducts={setProducts} setshowStyle={setshowStyle} />
          </Box>
        </Flex >
      </Flex >
    </>
  );
}

export default Listing;
