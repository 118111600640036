import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ProductsPage from '../pages/ProductsPage';
import SingleProductPage from '../pages/SingleProductPage';
import AboutPage from '../pages/AboutPage';
import ContactPage from '../pages/ContactPage';
import NotFound from '../pages/NotFoundPage'
import LoginSignup from '../components/LoginSignup/LoginSignup';
import Disclaimer from '../pages/Disclaimer';
import ShippingCancellationPage from '../pages/Shipping&Cancellation';
import ReturnAndExchange from '../pages/Return&Exchange';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import BlogPage from '../pages/BlogPage';
import AllAboutSilk from '../components/BlogPageComponents/AllAboutSilk';
import Estimate from '../components/BlogPageComponents/Estimate';
import ChooseYarn from '../components/BlogPageComponents/ChooseYarn';
import IdentifyNeedles from '../components/BlogPageComponents/IdentifyNeedles';
import IdentifySilk from '../components/BlogPageComponents/IdentifySilk';
import NeedlesTypes from '../components/BlogPageComponents/NeedlesTypes';
import KnittingPatterns from '../components/BlogPageComponents/KnittingPatterns';
import SelectNeedles from '../components/BlogPageComponents/SelectNeedles';
import YarnTypes from '../components/BlogPageComponents/YarnTypes';
import YarnPackaging from '../components/BlogPageComponents/YarnPackaging';
import YarnWeight from '../components/BlogPageComponents/YarnWeight';
import TandC from '../components/TandC/TandC';
import Faq from '../components/Faq/Faq';
import Checkout from '../pages/Checkout';
import Login from '../components/AdminComponents/Login';
import Createproducts from '../components/AdminComponents/Createproducts';
import Settings from '../components/AdminComponents/Settings';
import AdminDashboard from '../components/AdminComponents/AdminDashboard';
import Users from '../components/AdminComponents/Users';
import Orders from '../components/AdminComponents/Orders';
import ReturnOrders from '../components/AdminComponents/ReturnOrders';
import SingleReturnRequest from '../components/AdminComponents/SingleReturnRequest';
import Wishlist from '../components/ProductsPageComponents/Wishlist';
import Marketing from '../components/AdminComponents/Marketing';
import Integration from '../components/AdminComponents/Integration';
import Listing from '../components/AdminComponents/Listing';
import Stats from '../components/AdminComponents/Stats';
import AddNewListing from '../components/AdminComponents/AddNewListing';
import EditListing from '../components/AdminComponents/EditListing';
import ThankYouOrderPage from '../pages/ThankYouOrderPage';
import Fail from '../pages/Fail';
import Success from '../pages/Success';
import AllShippingProfiles from '../components/AdminComponents/AllShippingProfiles';
import EditShippingProfile from '../components/AdminComponents/EditShippingProfile';
import CopyListing from '../components/AdminComponents/CopyListing';
import AllDrafts from '../components/AdminComponents/AllDrafts';
import EditDraft from '../components/AdminComponents/EditDraft';
import AddShippingProfile from '../components/AdminComponents/AddShippingProfile';
import B2BPage from '../pages/B2BPage';
import OffersPage from '../pages/OffersPage';
import UserAccount from '../pages/UserAccount';
import UserAddress from '../pages/UserAddress';
import UserOrders from '../pages/UserOrders';
import Success1 from "../components/PaymentRazorpay/Success";
import Failure from "../components/PaymentRazorpay/Failure"
import Razorpay from '../components/PaymentRazorpay/Razorpay';
import ChatWithAdmin from '../pages/ChatWithAdmin';
import Messages from '../components/AdminComponents/Messages';
import PaymentPage from '../components/Checkout.jsx/PaymentPage';
import SingleOrder from '../components/AdminComponents/SingleOrder';
import LinkTreePage from '../pages/LinkTree';
import PrivateRoute from './PrivateRoute';
import Subscribers from '../components/AdminComponents/Subscribers';
import B2BADminPage from '../components/AdminComponents/B2BPage';
import UserSingleOrder from '../pages/UserSingleOrder';
import SingleListing from '../components/AdminComponents/SingleListing';
import VariationPage from '../components/AdminComponents/VariationPage';
import Createuser from '../components/AdminComponents/Createuser';
import StripePay from '../components/StripePayment/StripePay';
import StripeSuccess from "../components/StripePayment/StripeSuccess"
import StripeFailure from "../components/StripePayment/StripeFailure"
import ReviewsPage from '../pages/ReviewsPage';
import CashfreeSuccess from '../components/Checkout.jsx/CashfreeSuccess';
import HelloWorld from '../pages/HelloWorld';

const AllRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/cashfree-payment-successful" element={<CashfreeSuccess />} />
                <Route path="/global" element={<LinkTreePage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/products/:id" element={<SingleProductPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/login" element={<LoginSignup />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/shipping&cancellation" element={<ShippingCancellationPage />} />
                <Route path="/return&exchange" element={<ReturnAndExchange />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TandC />} />
                <Route path="/reviews" element={<ReviewsPage />} />
                <Route path="/Faq" element={< Faq />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route path="/blogs/all-about-silk" element={<AllAboutSilk />} />
                <Route path="/blogs/estimating-yarn" element={<Estimate />} />
                <Route path="/blogs/choose-yarn" element={<ChooseYarn />} />
                <Route path="/blogs/identify-needles" element={<IdentifyNeedles />} />
                <Route path="/blogs/identify-silk" element={<IdentifySilk />} />
                <Route path="/blogs/needles-types" element={<NeedlesTypes />} />
                <Route path="/blogs/knitting-patterns" element={<KnittingPatterns />} />
                <Route path="/blogs/selecting-knitting-needles" element={<SelectNeedles />} />
                <Route path="/blogs/yarn-types" element={<YarnTypes />} />
                <Route path="/blogs/yarn-packaging" element={<YarnPackaging />} />
                <Route path="/blogs/yarn-weight" element={<YarnWeight />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/b2b-wholesale" element={<B2BPage />} />
                <Route path="/offers" element={<OffersPage />} />
                <Route path="/choosepayment" element={<PaymentPage />} />
                <Route path="/my-account" element={<UserAccount />} />
                <Route path="/my-account/my-addresses" element={<UserAddress />} />
                <Route path="/my-account/my-orders" element={<UserOrders />} />
                <Route path="/my-account/my-orders/:id" element={<UserSingleOrder />} />
                <Route path="/orders/thankyou" element={<ThankYouOrderPage />} />


                <Route path="/manager" element={<Login />} />
                <Route path="/manager/dashboard" element={<PrivateRoute allowedRoles={['admin', 'editor', 'manager', 'marketing']}>
                    <AdminDashboard />
                </PrivateRoute>} />
                <Route path="/manager/products" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <Createproducts />  
                </PrivateRoute>} />
                <Route path="/manager/settings" element={<PrivateRoute allowedRoles={['admin']}>
                    <Settings />
                </PrivateRoute>} />
                <Route path="/manager/users" element={<PrivateRoute allowedRoles={['admin']}>
                    <Users />
                </PrivateRoute>} />
                <Route path="/manager/orders" element={<PrivateRoute allowedRoles={['admin', 'manager']}>
                    <Orders />
                </PrivateRoute>} />
                <Route path="/manager/return-orders" element={<PrivateRoute allowedRoles={['admin', 'manager']}>
                    <ReturnOrders />
                </PrivateRoute>} />
                <Route path="/manager/return-orders/:id" element={<PrivateRoute allowedRoles={['admin', 'manager']}>
                    <SingleReturnRequest />
                </PrivateRoute>} />
                <Route path="/manager/orders/:id" element={<PrivateRoute allowedRoles={['admin', 'manager']}>
                    <SingleOrder />
                </PrivateRoute>} />
                <Route path="/manager/allshippingprofile" element={<PrivateRoute allowedRoles={['admin']}>
                    <AllShippingProfiles />
                </PrivateRoute>} />
                <Route path="/manager/shippingprofiles/edit/:id" element={<PrivateRoute allowedRoles={['admin']}>
                    <EditShippingProfile />
                </PrivateRoute>} />
                <Route path="/manager/shippingprofiles/create/" element={<PrivateRoute allowedRoles={['admin']}>
                    <AddShippingProfile />
                </PrivateRoute>} />
                <Route path="/manager/listing" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <Listing />
                </PrivateRoute>} />
                <Route path="/manager/listing/:id" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <SingleListing />
                </PrivateRoute>} />
                <Route path="/manager/alldrafts" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <AllDrafts />
                </PrivateRoute>} />
                <Route path="/manager/stats" element={<PrivateRoute allowedRoles={['admin']}>
                    <Stats />
                </PrivateRoute>} />
                <Route path="/manager/marketing" element={<PrivateRoute allowedRoles={['admin', 'marketing']}>
                    <Marketing />
                </PrivateRoute>} />
                <Route path="/manager/subscribers" element={<PrivateRoute allowedRoles={['admin', 'marketing']}>
                    <Subscribers />
                </PrivateRoute>} />
                <Route path="/manager/b2b" element={<PrivateRoute allowedRoles={['admin']}>
                    <B2BADminPage />
                </PrivateRoute>} />
                <Route path="/manager/messages" element={<PrivateRoute allowedRoles={['admin']}>
                    <Messages />
                </PrivateRoute>} />
                <Route path="/manager/integration" element={<PrivateRoute allowedRoles={['admin']}>
                    <Integration />
                </PrivateRoute>} />
                <Route path="/manager/addnewlisting" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <AddNewListing />
                </PrivateRoute>} />
                <Route path="/manager/listings/edit/:id" element={
                    <EditListing />
                } />
                <Route path="/manager/drafts/edit/:id" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <EditDraft />
                </PrivateRoute>} />
                <Route path="/manager/listings/copy/:id" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <CopyListing />
                </PrivateRoute>} />
                <Route path="/manager/listings/variation/:id" element={<PrivateRoute allowedRoles={['admin', 'editor']}>
                    <VariationPage />
                </PrivateRoute>} />
                <Route path="/manager/createuser" element={<PrivateRoute allowedRoles={['admin']}>
                    <Createuser />
                </PrivateRoute>} />

                <Route path="/success" element={<Success />} />
                <Route path="/fail" element={<Fail />} />
                <Route path="/chatwithadmin" element={<ChatWithAdmin />} />

                {/* razorpay */}
                <Route path="/razorpay" element={<Razorpay />} />
                <Route path="/successrazorpay" element={<Success1 />} />
                <Route path="/failurerazorpay" element={<Failure />} />


                {/* STRIPE */}
                <Route path="/failurestripe" element={< StripeFailure />} />
                <Route path="/successstripe" element={< StripeSuccess />} />
                <Route path="/stripe" element={< StripePay />} />
                <Route path="*" element={<NotFound />} />


                <Route path="/hello" element={<HelloWorld />} />
            </Routes>
        </Router>
    )
}


export default AllRoutes;
