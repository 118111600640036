import React, { useEffect, useState } from 'react';
import { ChakraProvider, extendTheme, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Box, Icon } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AllRoutes from './routes/AllRoutes';
import { AppProvider } from './Context/AppContext';
import BackToTopButton from './components/Chat/BackToTopButton';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MdErrorOutline } from 'react-icons/md';
import ReactGA from 'react-ga4';

const baseUrl = process.env.REACT_APP_BASE_URL;


ReactGA.initialize('G-2PJPRXSS67');
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "1208px",
  lg: "1209px",
  xl: "1200px",
  "2xl": "1536px",
};
const theme = extendTheme({ breakpoints });

const queryClient = new QueryClient();

function App() {
  const [isActive, setIsActive] = useState(true);
  const [isServerDown, setIsServerDown] = useState(false);

  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const response = await fetch('https://api.digiblocks.tech/status');
        if (!response.ok) {
          throw new Error('Server is down');
        }
      } catch (error) {
        setIsServerDown(true);
      }
    };

    checkServerStatus();
  }, []);

  useEffect(() => {
    let timeout;
    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(logout, 30 * 60 * 1000);
    };
    const logout = () => {
      localStorage.removeItem('userToken');
      localStorage.removeItem("googleuser");
      localStorage.removeItem("basicuser");
      window.location.href = "${baseUrl}/login";
    };

    const handleMouseMove = () => {
      setIsActive(true);
      resetTimeout();
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsActive(false);
        resetTimeout();
      }
    };

    resetTimeout();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const closePopup = () => {
    setIsServerDown(false);
  };

  return (
    <GoogleOAuthProvider clientId="741632086121-hsodhnoqjgqo9njpmuabu2as61h9de6k.apps.googleusercontent.com">
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <AllRoutes />
            <BackToTopButton />
            {isServerDown && (
              <Modal isOpen={isServerDown} onClose={closePopup} isCentered>
                <ModalOverlay />
                <ModalContent bgGradient="linear(to-r, red.500, yellow.500)">
                  <ModalHeader color="white" display="flex" alignItems="center">
                    <Icon as={MdErrorOutline} w={6} h={6} mr={2} />
                    Server Unavailable
                  </ModalHeader>
                  <ModalCloseButton color="white" />
                  <ModalBody>
                    <Box textAlign="center">
                      <Text fontSize="lg" color="white" mb={4}>
                        Our server is currently down. Please try again later.
                      </Text>
                      <Text fontSize="md" color="white">
                        We are experiencing technical difficulties and are working to resolve the issue as soon as possible. Thank you for your patience.
                      </Text>
                      <Text fontSize="md" color="white" mt={4}>
                        If you need immediate assistance, please contact our support team at <a href="mailto:support@knitsilk.com" style={{ textDecoration: 'underline' }}>enquiry@knitsilk.com</a>.
                      </Text>
                    </Box>
                  </ModalBody>
                  <ModalFooter>
                    <Button colorScheme="yellow" onClick={closePopup}>
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </ChakraProvider>
        </QueryClientProvider>
      </AppProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
